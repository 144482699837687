import { render, staticRenderFns } from "./messageDeleteEditModal.vue?vue&type=template&id=78dd2227&scoped=true"
import script from "./messageDeleteEditModal.vue?vue&type=script&lang=js"
export * from "./messageDeleteEditModal.vue?vue&type=script&lang=js"
import style0 from "./messageDeleteEditModal.vue?vue&type=style&index=0&id=78dd2227&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78dd2227",
  null
  
)

export default component.exports