var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "padding-inline": "5px"
    }
  }, [!_vm.createSenderID ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleForm",
    staticClass: "form-container",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container",
    attrs: {
      "id": "__create"
    }
  }, [_c('div', {
    staticClass: "_select"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.overview.add_sender_id'),
      "prop": "sender_id"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "filterable": "",
      "placeholder": _vm.$t('dashboard.send_message.select_sender_id')
    },
    on: {
      "change": function ($event) {
        return _vm.handleInputChange($event, 'sender_id');
      }
    },
    model: {
      value: _vm.formData.sender_id,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sender_id", $$v);
      },
      expression: "formData.sender_id"
    }
  }, _vm._l(_vm.senderIDs, function (senderid, key) {
    return _c('el-option', {
      key: key,
      staticClass: "notranslate",
      attrs: {
        "label": senderid.sender_id,
        "value": senderid.id,
        "disabled": senderid.status != 1
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between",
      staticStyle: {
        "width": "100%"
      }
    }, [_c('div', [_vm._v(_vm._s(senderid.sender_id))]), senderid.status == 1 ? _c('img', {
      staticStyle: {
        "margin-left": "2em",
        "width": "18px",
        "height": "18px"
      },
      attrs: {
        "src": require("@/assets/Shield Done.svg"),
        "alt": "shield icon"
      }
    }) : _vm._e(), senderid.status == 0 ? _c('img', {
      staticStyle: {
        "margin-left": "2em",
        "width": "18px",
        "height": "18px"
      },
      attrs: {
        "src": require("@/assets/Shield_pending.svg"),
        "alt": "shield icon"
      }
    }) : _vm._e(), senderid.status == 2 ? _c('img', {
      staticStyle: {
        "margin-left": "2em",
        "width": "18px",
        "height": "18px"
      },
      attrs: {
        "src": require("@/assets/shield_declined.svg"),
        "alt": "shield icon"
      }
    }) : _vm._e(), senderid.status == 3 ? _c('img', {
      staticStyle: {
        "margin-left": "2em",
        "width": "18px",
        "height": "18px"
      },
      attrs: {
        "src": require("@/assets/shield_on_hold.svg"),
        "alt": "shield icon"
      }
    }) : _vm._e()])]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "button"
  }, [_c('el-button', {
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.createSenderID = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("dashboard.send_message.message_templates.form.message.create_sender_id")))])], 1)])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.send_message.message_templates.form.title.campaign_title'),
      "prop": "campaign_name"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": _vm.$t('dashboard.send_message.message_templates.form.title.placeholder_campaign')
    },
    on: {
      "change": function ($event) {
        return _vm.handleInputChange($event, 'campaign_name');
      }
    },
    model: {
      value: _vm.formData.campaign_name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "campaign_name", $$v);
      },
      expression: "formData.campaign_name"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('p', {
    staticStyle: {
      "margin": "0px"
    }
  }, [_c('el-form-item', {
    staticStyle: {
      "margin": "0px"
    },
    attrs: {
      "label": _vm.$t('dashboard.send_message.message_templates.form.content.label'),
      "required": "",
      "show-message": false
    }
  })], 1), _vm.checkIfGroup ? _c('div', {
    staticClass: "content_style"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.insertText('[fname]');
      }
    }
  }, [_vm._v(" [ " + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.content.first_name")) + "] ")]), _c('div', {
    on: {
      "click": function ($event) {
        return _vm.insertText('[lname]');
      }
    }
  }, [_vm._v(" [" + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.content.full_name")) + "] ")]), _c('div', {
    on: {
      "click": function ($event) {
        return _vm.insertText('[email]');
      }
    }
  }, [_vm._v(" [" + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.content.email")) + "] ")]), _c('div', {
    on: {
      "click": function ($event) {
        return _vm.insertText('[phone]');
      }
    }
  }, [_vm._v(" [" + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.content.phone")) + "] ")]), _c('div', {
    on: {
      "click": function ($event) {
        return _vm.insertText('[dob]');
      }
    }
  }, [_vm._v(" [" + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.content.dob")) + "] ")])]) : _vm._e(), _vm.checkIfExcel == 'success' ? _c('small', {
    staticClass: "small-header-text"
  }, [_vm._v(_vm._s(_vm.$t("dashboard.send_message.message_templates.form.content.add_column")))]) : _vm._e(), _vm.checkIfExcel == 'success' ? _c('div', {
    staticClass: "__message__form__text contains-custom-headers notranste"
  }, _vm._l(_vm.payloadData.headers, function (header, key) {
    return _c('button', {
      key: key,
      staticClass: "notranslate",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.insertText(`[${header}]`);
        }
      }
    }, [_vm._v(" " + _vm._s(`[${header}]`) + " ")]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "message"
    }
  }, [_c('el-input', {
    ref: "message",
    attrs: {
      "type": "textarea",
      "rows": 6,
      "autocomplete": "off",
      "placeholder": _vm.$t('dashboard.send_message.message_templates.form.message.placeholder'),
      "id": "simpleTextArea"
    },
    on: {
      "change": function ($event) {
        return _vm.handleInputChange($event, 'message');
      }
    },
    model: {
      value: _vm.formatASCII,
      callback: function ($$v) {
        _vm.formatASCII = $$v;
      },
      expression: "formatASCII"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "text_description"
  }, [_vm._v(" " + _vm._s(_vm.formData.message.length) + " " + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.message.characters_entered")) + " ")]), _vm.findNumberOfRecipient ? _c('p', {
    staticClass: "text_description pl-4"
  }, [_vm._v(" " + _vm._s(_vm.findNumberOfRecipient) + " " + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.message.per_recipient")) + " ")]) : _vm._e(), _vm.findNumberOfRecipient ? _c('div', {
    staticClass: "text_description pl-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.form.message.encoding_type")) + " "), _vm.getEncoding == 'GSM_7BIT' || _vm.getEncoding == 'GSM_7BIT_EX' ? _c('span', {
    staticStyle: {
      "color": "#36d962"
    }
  }, [_vm._v("GSM_7BIT")]) : _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(_vm._s(_vm.getEncoding))])]) : _vm._e()]), _c('div', {
    staticClass: "use-gsm"
  }, [_c('div', {
    staticClass: "check-gsm-only"
  }, [_c('el-checkbox', {
    attrs: {
      "label": _vm.$t('dashboard.send_message.message_templates.form.message.gsm_only')
    },
    model: {
      value: _vm.useGSMOnly,
      callback: function ($$v) {
        _vm.useGSMOnly = $$v;
      },
      expression: "useGSMOnly"
    }
  })], 1), _c('div', {
    staticClass: "info-gsm-only"
  }, [_c('el-button', {
    staticClass: "info-gsm-button",
    attrs: {
      "size": "small",
      "title": _vm.$t('dashboard.send_message.message_templates.form.message.learn_more')
    },
    on: {
      "click": function ($event) {
        _vm.showGSM_Only_Info = true;
      }
    }
  }, [_c('el-icon', {
    staticClass: "info-icon",
    attrs: {
      "name": "info"
    }
  })], 1)], 1)])])])])], 1) : _vm._e(), _vm.createSenderID ? _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row px-3"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.sender_id.label'),
      "prop": "sender"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": _vm.$t('dashboard.sender_id.placeholder')
    },
    model: {
      value: _vm.formData.sender,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sender", $$v);
      },
      expression: "formData.sender"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.sender_id.purpose'),
      "prop": "purpose"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return undefined;
      },
      proxy: true
    }], null, false, 522070287)
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 7,
      "autocomplete": "off",
      "placeholder": _vm.$t('dashboard.sender_id.placeholder_purpose')
    },
    model: {
      value: _vm.formData.purpose,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "purpose", $$v);
      },
      expression: "formData.purpose"
    }
  })], 1)], 1)]), _vm.openSenderIDError ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('Alert', {
    attrs: {
      "message": _vm.openSenderIDError,
      "type": _vm.type
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-md-12 d-flex justify-content-between mb-5 mt-3"
  }, [_c('button', {
    staticClass: "cancel mr-2 notranslate",
    on: {
      "click": function ($event) {
        _vm.createSenderID = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")]), _c('el-button', {
    staticStyle: {
      "padding-top": "1.1em",
      "padding-bottom": "1.1em"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.submit('ruleForm', 'createSenderID');
      }
    }
  }, [_vm._v("create")])], 1)])])], 1)]) : _vm._e(), _vm.errors ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  })], 1) : _vm._e(), !_vm.createSenderID ? _c('div', {
    staticClass: "mt-3 mb-4 col-md-12 d-flex justify-content-between",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('button', {
    staticClass: "___back__btn",
    on: {
      "click": _vm.cancelModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.back")) + " ")]), !_vm.isEdit ? _c('button', {
    staticClass: "cancel mr-1",
    on: {
      "click": function ($event) {
        return _vm.sendMessageTo('ruleForm', 'schedule');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.scheduled_messages.schedule_message")) + " ")]) : _vm._e(), !_vm.isEdit && _vm.checkMessageScheduled == false ? _c('el-button', {
    staticStyle: {
      "padding-right": "2em",
      "color": "#fff",
      "padding-left": "2em"
    },
    attrs: {
      "loading": _vm.loading,
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.sendMessageTo('ruleForm', 'send');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("misc.button.next")) + " ")]) : _vm._e(), _vm.isEdit ? _c('el-button', {
    staticStyle: {
      "padding-right": "2em",
      "color": "#fff",
      "padding-left": "2em"
    },
    attrs: {
      "loading": _vm.loading,
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.sendMessageTo('ruleForm', 'edit');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("misc.button.edit")) + " ")]) : _vm._e()], 1) : _vm._e(), _c('el-dialog', {
    staticClass: "main-modal",
    attrs: {
      "visible": _vm.showGSM_Only_Info,
      "title": _vm.$t('dashboard.send_message.message_templates.notes.title'),
      "modal": false,
      "top": '3rem',
      "width": _vm.dynamicModalWidth
    },
    on: {
      "update:visible": function ($event) {
        _vm.showGSM_Only_Info = $event;
      }
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.title")) + " ")])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "long-form-sms"
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.$t("dashboard.send_message.message_templates.notes.long_sms_messages")))])]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.exceed_160")) + " ")]), _c('br'), _c('p', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.see_below")) + " ")]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.standard_gsm.character_counts.2_message_parts")) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.standard_gsm.character_counts.3_message_parts")) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.standard_gsm.character_counts.4_message_parts")) + " ")])])]), _c('div', {
    staticClass: "utf-16"
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.title")))])]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.description")) + " ")]), _c('br'), _c('p', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.note")) + " ")]), _c('br'), _c('p', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.see_below")) + " ")]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.character_counts.2_message_segments")) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.character_counts.3_message_segments")) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.message_templates.notes.unicode.character_counts.4_message_segments")) + " ")])])])]), _c('span', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.showGSM_Only_Info = false;
      }
    }
  }, [_vm._v("OK")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };