var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {}, [_c('div', {
    staticClass: "modal-header d-flex justify-content-between pt-4 pb-2"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.create_voice_message.voice")) + " ")])]), _c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('audio', {
    ref: "audio",
    attrs: {
      "controls": "",
      "id": "audio-player",
      "src": `${_vm.audioNote}`
    }
  })]), _c('div', {
    staticClass: "col-md-12 ___ft_btns"
  }, [_c('button', {
    staticClass: "__ft__cancel",
    on: {
      "click": _vm.hideMainModal
    }
  }, [_vm._v(_vm._s(_vm.$t("misc.button.close")))])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12 mt-4"
  }, [_c('div', {
    staticClass: "__audio_container"
  })]);
}];
export { render, staticRenderFns };