<template>
  <div
    class="row justify-content-center"
    style="padding-right: 1em; padding-left: 1em">
    <div class="col-md-12" v-if="displayType === 'template'">
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        @submit.prevent.native="checkEnter">
        <div class="row justify-content-center">
          <div class="col-md-12 mb-3">
            <div class="form_input_container">
              <el-form-item
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.title.label'
                  )
                "
                prop="title">
                <el-input
                  type="text"
                  autocomplete="off"
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.title.placeholder'
                    )
                  "
                  v-model="formData.title"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-12">
            <el-upload
              class="avatar-uploader"
              style="
                cursor: pointer;
                position: relative;
                overflow: hidden;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
              action="#"
              :show-file-list="isFile"
              :auto-upload="false"
              accept=".mp3, .wav, .mpeg"
              :multiple="false"
              :on-change="handleChange">
              <div class="el-upload__text">
                <p class="__upload__audio__file">
                  {{
                    $t("dashboard.voice_templates.table.form.title.upload_file")
                  }}
                </p>
                <p class="file_type">
                  {{ $t("dashboard.voice_templates.table.form.title.warning") }}
                </p>
                <img src="../../../assets/Voice 2.svg" alt="" />
                <p class="drag_file">
                  {{ $t("dashboard.voice_templates.table.form.title.document")
                  }}<em>
                    {{
                      $t("dashboard.voice_templates.table.form.title.link")
                    }}</em
                  >
                </p>
              </div>
            </el-upload>
            <!-- <small class="pt-3"> {{ fileName }}</small> -->
          </div>
          <div
            class="col-md-12 __select__message__template"
            v-if="showSenderID">
            <div class="form_input_container">
              <el-form-item label="Add Sender ID" prop="sender_id">
                <el-select
                  style="width: 100%"
                  v-model="formData.sender_id"
                  filterable
                  placeholder="Select a Sender ID"
                  class="notranslate">
                  <el-option
                    v-for="(senderid, key) in senderIDs"
                    :key="key"
                    :label="senderid.sender_id"
                    :value="senderid.id">
                    <div
                      style="width: 100%"
                      class="d-flex justify-content-between">
                      <div>{{ senderid.sender_id }}</div>
                      <img
                        style="margin-left: 2em; width: 18px; height: 18px"
                        src="@/assets/Shield Done.svg"
                        alt="" />
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2" v-if="errors">
          <Alert :message="errors" :type="type" />
        </div>
      </el-form>
    </div>
    <div
      class="col-md-12 mt-3 mb-4 d-flex justify-content-between"
      style="width: 100%">
      <button class="___back__btn" v-if="!isEdit" @click="back('cancel')">
        {{ $t("misc.button.close") }}
      </button>
      <button
        class="___back__btn"
        v-if="isEdit"
        style="padding-top: 1.1em; padding-bottom: 1.1em"
        @click="back('back')">
        {{ $t("misc.button.back") }}
      </button>
      <div class="left_btn contains-all-buttons" v-if="!showSenderID">
        <button
          class="save_and_send mr-3"
          @click="submit('ruleForm', 'saveAndSend')"
          v-if="!isEdit && !messageForm.isBirthDay && showScheduled === false">
          <!-- {{ quickVoice ? "Schedule Message" : "Save and Send" }} -->
          {{
            $t("dashboard.send_message.scheduled_messages.scheduled_message")
          }}
        </button>
        <el-button
          v-if="!isEdit && isTypeScheduled == false"
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          :loading="loading"
          @click="submit('ruleForm', 'save')"
          >{{ quickVoice ? "Send" : "Save" }}</el-button
        >
        <el-button
          v-if="isEdit"
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          :loading="loading"
          @click="submit('ruleForm', 'edit')">
          {{ $t("misc.button.edit") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  export default {
    name: "create-message-template",
    props: {
      payload: {
        type: Object,
      },
    },
    data() {
      return {
        messages: "",
        displayType: "template",
        success: "",
        errors: "",
        modalType: "",
        messageTemplates: [],
        loadingTemplate: false,
        loading: false,
        type: "alert-danger",
        showSenderID: false,
        isFile: false,
        fileName: "",
        rules: {
          title: [
            {
              required: true,
              message: "Campaign name is required",
              trigger: "change",
            },
          ],
          sender_id: [
            {
              required: true,
              message: "Sender ID is required",
              trigger: "change",
            },
          ],
        },
        formData: {
          type: "",
          title: "",
          body: "",
          id: "",
          sender_id: "",
          perMessage: 0,
        },
        sendMessage: {},
        sendMessageRules: {},
        selectedKey: "",
        isEdit: false,
        fileList: [],
      };
    },
    watch: {
      fileList(val) {
        if (val.length > 0) {
          this.errors = null;
        }
      },
    },
    mounted() {
      let editedData = store.getters["uistate/contactForm"];
      if (editedData.isEdit) {
        this.formData = {
          type: editedData.editData.selectedMessage.type,
          title: editedData.editData.selectedMessage.title,
          body: editedData.editData.selectedMessage.body,
          id: editedData.editData.selectedMessage.id,
        };
        this.selectedKey = editedData.editData.selectedKey;
        this.isEdit = true;
      }
    },
    computed: {
      quickVoice() {
        return store.getters["uistate/quickVoice"];
      },
      senderIDs() {
        return store.getters["senderid/senderIDs"];
      },
      messageForm() {
        return store.getters["uistate/contactForm"];
      },
      groups() {
        return store.getters["groups/all_groups"];
      },
      templates() {
        return store.getters["campaigns/templates"];
      },
      showScheduled() {
        return store.getters["uistate/fromVoice"];
      },
      isTypeScheduled() {
        return store.state.uistate.isScheduledType;
      },
    },
    methods: {
      sendVoiceToEXcel() {
        const formDataFromStore = store.state.modal.formData;
        this.loading = true;
        this.errors = null;
        let formData = new FormData();
        formData.append("campaign_name", this.formData.title);
        formData.append("audio", this.fileList);
        formData.append("excel_file", this.payload.excel_contacts);
        formData.append("phone_number_column", formDataFromStore.header);
        store
          .dispatch("voice/quickExcel", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              res.data.from = "excel";
              this.$emit("saveVoiceMessage", res.data);
              this.$mixpanel.track("Send Excel Voice Message");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Excel Voice Message");
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Excel Voice Message");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Excel Voice Message"
                );
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error. try again.";
            }
            if (err.response.status == 404) {
              this.errors = err.response.data.message;
            }
            if (err.response.status) {
              this.$notify({
                title: "Error",
                message: err.response.data.message,
                type: "error",
                duration: 5000,
              });
            }
          });
      },
      sendQuickVoiceToGroup() {
        (this.loading = true), (this.errors = null);
        let formData = new FormData();
        formData.append("campaign_name", this.formData.title);
        formData.append("audio", this.fileList);
        formData.append("group_ids[]", JSON.stringify(this.payload.groupIDs));
        store
          .dispatch("voice/voiceGroup", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              res.data.from = "group";
              this.$emit("saveVoiceMessage", res.data);
              // Add mixpanel analytics
              this.$mixpanel.track("Send Group Voice Message");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Group Voice Message");
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Group Voice Message");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Group Voice Message"
                );
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error. try again.";
            }
            if (err.response.status == 404) {
              this.errors = err.response.data.message;
            }
          });
      },
      sendQuickVoiceMessage() {
        (this.loading = true), (this.errors = null);
        let formData = new FormData();
        formData.append("recipients_format", this.payload.recipients_format);
        formData.append("campaign_name", this.formData.title);
        formData.append("audio", this.fileList);
        formData.append("recipients", this.payload.recipients);
        store
          .dispatch("voice/voiceQuick", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              res.data.from = "individual";
              this.$emit("saveVoiceMessage", res.data);
              this.$mixpanel.track("Send Quick Voice Message");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Quick Voice Message");
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Quick Voice Message");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Quick Voice Message"
                );
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error. try again.";
            }
            if (err.response && err.response.status == 404) {
              if (
                err.response.data.message ===
                "validation failed. please try gain."
              ) {
                return (this.errors = Object.values(
                  err.response.data.data
                ).toString());
              }
              this.errors = err.response.data.message;
            }
          });
      },

      handleFileExceed() {
        this.$message.error("You can only upload one file");
      },
      handleChange(file, fileList) {
        const isLt5M = file.size / 1024 / 1024 > 5;
        this.fileName = file.name;
        if (isLt5M) {
          return (this.errors = "Audio file size cannot be more than 5MB.");
        }
        this.fileList = file.raw;
        this.fileUploadSuccess = true;
        this.isFile = true;
        this.errors = null;
      },
      cancelSenderID() {
        this.showSenderID = false;
      },
      submit(formName, type) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.fileList.length == 0) {
              this.errors = "Please Upload an audio file";
              this.loading = false;
              return;
            } else {
              this.errors = null;
            }
            if (type == "saveAndSend") {
              if (this.quickVoice) {
                let newFormData = {
                  campaign_name: this.formData.title,
                  audio: this.fileList,
                };
                this.$emit("scheduleVoiceMessage", newFormData);
                if (
                  this.payload.recipients &&
                  this.payload.from == "individual"
                ) {
                  return store.dispatch("uistate/addContactFormData", {
                    title: "Schedule Voice message",
                    type: "calender",
                    voiceFrom: "quick",
                    quickVoice: true,
                  });
                }
                if (this.payload.groupIDs && this.payload.from == "group") {
                  return store.dispatch("uistate/addContactFormData", {
                    title: "Schedule Voice message",
                    type: "calender",
                    voiceFrom: "group",
                    quickVoice: true,
                  });
                }
                if (
                  this.payload.excel_contacts &&
                  this.payload.from == "excel"
                ) {
                  return store.dispatch("uistate/addContactFormData", {
                    title: "Schedule Voice message",
                    type: "calender",
                    voiceFrom: "excel",
                    quickVoice: true,
                  });
                }
              }
              return this.saveAndSend();
            }

            if (type == "save") {
              if (this.quickVoice) {
                //   Check where user is coming from before using appropriate method
                if (
                  this.payload.recipients &&
                  this.payload.from == "individual"
                ) {
                  return this.sendQuickVoiceMessage();
                }
                if (this.payload.groupIDs && this.payload.from == "group") {
                  return this.sendQuickVoiceToGroup();
                }
                if (
                  this.payload.excel_contacts &&
                  this.payload.from == "excel"
                ) {
                  return this.sendVoiceToEXcel();
                }
              }
              return this.save();
            }

            return this.edit();
          } else {
            return false;
          }
        });
      },
      save() {
        this.loading = true;
        this.errors = null;
        let formData = new FormData();
        formData.append("voice_note_title", this.formData.title);
        formData.append("voice_note", this.fileList);
        if (this.messageForm.isBirthDay) {
          formData.append("type", 3);
        }
        store
          .dispatch("voice/saveVoiceNote", formData)
          .then((res) => {
            if (res) {
              if (res) {
                this.loading = false;
                this.$emit("saveVoiceTemplate", res.data);
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error. try again.";
            }
            if (err.response && err.response.status == 404) {
              if (
                err.response.data.message ==
                "validation failed. please try gain."
              ) {
                return (this.errors = Object.values(
                  err.response.data.data
                ).toString());
              }
              this.errors = err.response.data.message;
            }
          });
      },
      edit() {
        store
          .dispatch("voice/saveVoiceNote", this.formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              let payload = {
                data: res.data.data,
                key: this.selectedKey,
                message: res.data.message,
              };
              this.$emit("editTemplate", payload);
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error. try again.";
            }
          });
      },
      insertText(data) {
        this.formData.body = this.formData.body + " " + data + " ";
      },
      back(type) {
        this.showSenderID = false;
        this.errors = null;
        this.fileName = "";
        this.fileList = [];
        this.formData.title = null;
        this.$emit("closeModal", type);
      },
      saveAndSend() {
        let payload = {
          campaign_name: this.formData.title,
          audio: this.fileList,
        };
        this.$emit("goToSelectUsers", payload);
      },
    },
  };
</script>
<style scoped>
  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  .save_and_send {
    background: #f5f5f5;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 1.1em 0.9em;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9e9ea7;
  }

  .__select__message__template {
    width: 90%;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-upload__text em {
    color: #f7921c;
  }

  .__upload__audio__file {
    font-style: normal;
    font-weight: 500;
    font-size: 16.3721px;
    line-height: 20px;
    text-align: center;
    color: #575761;
    padding-top: 1em;
  }

  .file_type {
    font-style: normal;
    font-weight: normal;
    font-size: 12.7338px;
    line-height: 20px;
    text-align: center;
    color: #575761;
  }

  .drag_file {
    font-style: normal;
    font-weight: normal;
    font-size: 12.7338px;
    line-height: 20px;
    color: #575761;
  }

  .el-upload__text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border: 1px dashed #d9d9d9;
    width: 100%;
    height: 230px;
  }

  .el-upload__text img {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .el-upload__text:hover {
    border-color: #f7921c !important;
  }

  .contains-all-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 5rem;
  }
</style>
