import $ from 'jquery';
import store from '@/state/store.js';
export default {
  name: 'message-delete-edit',
  props: {
    payload: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      errors: '',
      fromVoice: false,
      type: 'alert-danger',
      success: ''
    };
  },
  mounted() {},
  methods: {
    deleteMessage() {
      this.loading = true;
      let data = {
        id: this.payload.selectedMessage.id
      };
      let payloadMessage = store.getters['uistate/contactForm'];
      //delete user  key
      if (this.payload.deleteType && this.payload.deleteType == "_deleteUser") {
        let payloadData = {
          userDeleteType: this.payload.userDeleteType,
          data: {
            user_id: this.payload.selectedMessage.id
          },
          index: this.payload.selectedKey,
          from: this.payload.from
        };
        return store.dispatch('admin/users/deleteUserAccount', payloadData).then(res => {
          this.loading = false;
          this.success = 'User deleted successfully';
          this.$emit('deleteSuccess', {
            message: 'User deleted successfully',
            key: this.payload.selectedKey
          });
        }).catch(err => {
          var _err$response;
          this.loading = false;
          if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
            this.errors = "Network error try again.";
          }
        });
      }
      //delete V1 key
      if (this.payload.deleteType && this.payload.deleteType == "_deleteApiV1Key") {
        let data = {
          id: this.payload.selectedMessage.id
        };
        return store.dispatch('developer/deleteApiV1', data).then(data => {
          if (data) {
            this.loading = false;
            this.success = data.message;
            this.$emit('deleteSuccess', {
              message: data.message,
              key: this.payload.selectedKey
            });
          }
        }).catch(err => {
          var _err$response2;
          this.loading = false;
          if ((err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
            this.errors = "Network error try again.";
          }
        });
      }
      //delete V2 key
      if (this.payload.deleteType && this.payload.deleteType == "_deleteApiV2Key") {
        let data = {
          id: this.payload.selectedMessage.id,
          key: this.payload.selectedMessage.selectedKey
        };
        return store.dispatch('developer/deleteApiV2', data).then(data => {
          if (data) {
            this.loading = false;
            this.success = data.message;
            this.$emit('deleteSuccess', {
              message: data.message,
              key: this.payload.selectedKey
            });
          }
        }).catch(err => {
          var _err$response3;
          this.loading = false;
          if ((err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
            this.errors = "Network error try again.";
          }
        });
      }
      //delete scheduled voice message
      if (this.payload.deleteType && this.payload.deleteType == "scheduled__voice") {
        let data = {
          id: this.payload.selectedMessage.campaign_id
        };
        return store.dispatch('voice/deleteVoiceMessage', data).then(res => {
          if (res) {
            this.loading = false;
            this.success = res.data.message;
            this.$emit('deleteSuccess', {
              message: res.data.message,
              key: this.payload.selectedKey
            });
          }
        }).catch(err => {
          var _err$response4;
          this.loading = false;
          if ((err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) == undefined) {
            this.errors = "Network error try again.";
          }
        });
      }
      //delete voice message 
      if (this.payload.deleteType && this.payload.deleteType == "voice" || payloadMessage.message && payloadMessage.message.deleteType == 'voice') {
        let data = {
          voice_note_id: this.payload.selectedMessage.voice_note_id || payloadMessage.message && payloadMessage.message.selectedMessage.voice_note_id,
          key: this.payload.selectedKey || payloadMessage.message && payloadMessage.message.selectedKey
        };
        return store.dispatch('voice/deleteVoiceNote', data).then(res => {
          if (res) {
            this.loading = false;
            this.success = res.data.message;
            this.$emit('deleteSuccess', {
              message: res.data.message,
              key: this.payload.selectedKey
            });
          }
        }).catch(err => {
          var _err$response5;
          this.loading = false;
          if ((err === null || err === void 0 || (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.data) == undefined) {
            this.errors = "Network error try again.";
          }
        });
      }
      // delete scheduled messages
      if (this.payload.deleteType && this.payload.deleteType == "__scheduled") {
        let data = {
          id: this.payload.selectedMessage.campaign_id
        };
        return store.dispatch('campaigns/deleteScheduledMessages', data).then(res => {
          if (res) {
            this.loading = false;
            this.success = res.data.message;
            this.$emit('deleteSuccess', {
              message: res.data.message,
              key: this.payload.selectedKey
            });
          }
        }).catch(err => {
          var _err$response6;
          this.loading = false;
          if ((err === null || err === void 0 || (_err$response6 = err.response) === null || _err$response6 === void 0 ? void 0 : _err$response6.data) == undefined) {
            this.errors = "Network error try again.";
          }
        });
      }
      // delete template messages
      store.dispatch('campaigns/deleteTemplate', data).then(res => {
        if (res) {
          this.loading = false;
          this.success = res.data.message;
          this.$emit('deleteSuccess', {
            message: res.data.message,
            key: this.payload.selectedKey
          });
        }
      }).catch(err => {
        var _err$response7;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response7 = err.response) === null || _err$response7 === void 0 ? void 0 : _err$response7.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    hideMainModal() {
      this.$emit('closeModal');
      this.success = '';
      this.errors = '';
      this.loading = false;
    }
  }
};