import store from '@/state/store.js';
import messageModal from '../messages/messageModal.vue';
export default {
  name: 'view-message',
  components: {
    messageModal
  },
  props: {
    payload: {
      type: Object
    }
  },
  data() {
    return {
      showSenderID: false,
      audioState: "pause",
      pauseTitle: 'play',
      currentTime: '2:00',
      toTalTime: '3:00',
      hideSendMessage: false
    };
  },
  mounted() {
    const audio = document.querySelector('#audio-player');
    document.querySelector(".modal").addEventListener("click", () => {
      audio.pause();
    });
  },
  computed: {
    senderIDs() {
      return store.getters['senderid/senderIDs'];
    },
    audioNote() {
      let data = store.getters['uistate/contactForm'];
      this.hideSendMessage = data.message.audio_path ? true : false;
      return data.message.body || data.message.audio_path;
    },
    audioDuration() {
      const audio = document.querySelector('audio');
      return audio ? this.calculateTime(audio.duration) : "0:00";
    }
  },
  methods: {
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
    playAudio() {
      let audio = this.$refs.audio;
      if (this.audioState === 'pause') {
        const audio = document.querySelector('audio');
        audio.play();
        this.audioState = "play";
      } else {
        const audio = document.querySelector('audio');
        audio.pause();
        this.audioState = "pause";
      }
    },
    hideMainModal() {
      this.$emit('closeModal');
      const audio = document.querySelector('audio');
      audio.pause();
      this.audioState = "pause";
    },
    deleteVoice() {
      let data = store.getters['uistate/contactForm'];
      let dataPayload = {
        currentModal: 'delete',
        selectedKey: data.key,
        deleteType: 'voice',
        selectedMessage: data.message,
        deleteMessage: 'Deleting Voice Message will remove it permenantly.'
      };
      store.dispatch('uistate/addContactFormData', {
        title: "",
        type: 'delete',
        message: dataPayload
      });
    },
    sendMessage() {
      let data = store.getters['uistate/contactForm'];
      let payload = {
        campaign_name: data.message.title,
        audio: data.message.body,
        isScheduleVoice: true,
        fromSelectSenderIDForVoice: true,
        fromVoice: true
      };
      store.dispatch('uistate/addContactFormData', {
        title: "Select sender ID",
        type: 'selectSenderId',
        message: payload
      });
    }
  }
};