var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "select-route-container",
    class: {
      'full-width': _vm.fullWidth
    }
  }, [_c('div', {
    staticClass: "left-side"
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Select a route",
      "size": _vm.inputSize
    },
    on: {
      "change": _vm.onRouteChange
    },
    model: {
      value: _vm.selectedRoute,
      callback: function ($$v) {
        _vm.selectedRoute = $$v;
      },
      expression: "selectedRoute"
    }
  }, _vm._l(_vm.routeOptions, function (route, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": route.label,
        "value": route.value
      }
    });
  }), 1)], 1), _vm.showHelp ? _c('div', {
    staticClass: "right-side"
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Help"
    }
  }, [_c('el-button', {
    staticStyle: {
      "padding": "3px 4px",
      "font-size": "15px",
      "line-height": "0"
    },
    attrs: {
      "type": "primary",
      "round": "",
      "icon": "el-icon-info",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        _vm.helpVisible = true;
      }
    }
  })], 1), _c('el-dialog', {
    attrs: {
      "visible": _vm.helpVisible,
      "title": "Route Options",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.helpVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "help-dialog"
  }, [_c('ul', [_c('li', [_c('strong', [_vm._v("Standard:")]), _vm._v(" "), _c('br'), _vm._v(" The standard route is automatically selected as the default option. Message costs are "), _c('strong', {
    staticStyle: {
      "color": "#f56c6c"
    }
  }, [_vm._v("deducted from your SMS/bonus balance,")]), _vm._v(" and it assigns normal priority to your messages. ")]), _c('br'), _c('li', [_c('strong', [_vm._v("High Priority:")]), _c('br'), _vm._v(" Opting for the High Priority route deducts the message cost "), _c('strong', {
    staticStyle: {
      "color": "#f56c6c"
    }
  }, [_vm._v(" directly from your wallet balance.")]), _vm._v(" This prioritizes your message at the top and involves a higher cost. ")])])])])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };