<template>
  <div
    class="row justify-content-center"
    style="padding-right: 1em; padding-left: 1em">
    <div class="col-md-12" v-if="!showFileUploader">
      <div class="sample_excel table-responsive-md">
        <table class="table table-borderless example-table">
          <thead>
            <tr>
              <th scope="col">A</th>
              <th scope="col">B</th>
              <th scope="col">C</th>
              <th scope="col">D</th>
              <th scope="col">E</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="excel-header-example">firstname</th>
              <td class="excel-header-example">lastname</td>
              <td class="excel-header-example">phone</td>
              <td class="excel-header-example">email</td>
              <td class="excel-header-example">date_of_birth</td>
            </tr>
            <tr>
              <th scope="row">George</th>
              <td>Darko</td>
              <td>0202346754</td>
              <td>darko@mail.com</td>
              <td>19/09/98</td>
            </tr>
            <tr class="tr_stripped">
              <th scope="row">George</th>
              <td>Darko</td>
              <td>0202346754</td>
              <td>darko@mail.com</td>
              <td>19/09/98</td>
            </tr>
            <tr>
              <th scope="row">George</th>
              <td>Darko</td>
              <td>0202346754</td>
              <td>darko@mail.com</td>
              <td>19/09/98</td>
            </tr>
            <tr class="tr_stripped">
              <th scope="row">George</th>
              <td>Darko</td>
              <td>0202346754</td>
              <td>darko@mail.com</td>
              <td>19/09/98</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="main_text">
        <h4 class="uppercase">
          <strong class="note-excel">{{
            $t("dashboard.send_message.sms.excel_sms.note.please_note")
          }}</strong>
        </h4>
        <p>
          {{ $t("dashboard.send_message.sms.excel_sms.note.required") }}
        </p>
        <h5 style="font-weight: bold">
          {{ $t("dashboard.send_message.sms.excel_sms.note.max") }}
          <span class="note-excel"> 5MB </span>
        </h5>
      </div>
    </div>
    <div class="col-md-12" v-else>
      <div class="row">
        <div
          style="width: 100%"
          class="col-md-12 __select__message__template excel-container">
          <el-upload
            class="avatar-uploader"
            drag
            style="
              cursor: pointer;
              width: 100% !important;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
            action="#"
            :show-file-list="true"
            :auto-upload="false"
            accept=".xlsx, .xls, .csv"
            :multiple="false"
            :on-exceed="handleFileExceed"
            :file-list="fileList"
            :on-change="handleChange">
            <div class="el-upload__text">
              <p class="__upload__audio__file">
                {{ $t("dashboard.send_message.sms.excel_sms.import") }}
              </p>
              <p class="file_type">
                {{ $t("dashboard.send_message.sms.excel_sms.type") }}
              </p>
              <img
                src="../../../assets/vscode-icons_file-type-excel.svg"
                alt="excel icon" />
              <p class="drag_file">
                {{ $t("dashboard.send_message.sms.excel_sms.drag_or") }}
                <em>
                  {{ $t("dashboard.send_message.sms.excel_sms.browse") }}</em
                >
              </p>
            </div>
          </el-upload>
          <!-- Caution -->
        </div>
        <p v-if="checkSheetLength" class="warning-excel-sheet">
          {{ $t("dashboard.send_message.sms.excel_sms.multiple_sheets") }}
        </p>
        <div class="col-md-12" v-if="!hideHeaders">
          <el-form>
            <div class="row">
              <div class="col-md-12" v-if="headers.length">
                <el-form-item required>
                  <el-checkbox v-model="checked" hidden>
                    {{
                      $t(
                        "dashboard.send_message.sms.excel_sms.first_row_header"
                      )
                    }}
                  </el-checkbox>
                </el-form-item>
              </div>
              <div class="col-md-12" v-if="headers.length">
                <el-form-item
                  :label="
                    $t('dashboard.send_message.sms.excel_sms.form.phone.label')
                  "
                  required="">
                  <el-select
                    label=""
                    style="width: 100%"
                    v-model="header"
                    filterable
                    :placeholder="
                      $t(
                        'dashboard.send_message.sms.excel_sms.form.phone.placeholder'
                      )
                    ">
                    <el-option
                      v-for="(header, key) in displayedHeaders"
                      :key="key"
                      :label="header"
                      :value="header"
                      class="notranslate"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-2" v-if="errors">
      <Alert :message="errors" :type="'alert-danger'" />
    </div>
    <div
      class="mt-3 mb-4 col-md-12 d-flex justify-content-between"
      style="width: 100%"
      v-if="!showFileUploader">
      <button class="___back__btn notranslate" @click="cancelModal">
        {{ $t("misc.button.back") }}
      </button>
      <el-button
        style="padding-right: 2em; color: #fff; padding-left: 2em"
        type="primary"
        @click="showFileUpload">
        {{
          $t("dashboard.send_message.sms.excel_sms.form.phone.button")
        }}</el-button
      >
    </div>
    <div
      class="mt-3 mb-4 col-md-12 d-flex justify-content-between"
      style="width: 100%"
      v-if="showFileUploader">
      <button class="___back__btn" @click="showFileUploader = false">
        {{ $t("misc.button.back") }}
      </button>
      <el-button
        v-if="fileUploadSuccess && !fromVoice"
        style="padding-right: 2em; color: #fff; padding-left: 2em"
        type="primary"
        @click="goToTypeMessage">
        {{ $t("misc.button.continue") }}
      </el-button>
      <button
        form="voice"
        v-if="fileUploadSuccess && fromVoice"
        class="schedule_message____"
        @click="sheduleMessage">
        {{ $t("dashboard.send_message.scheduled_messages.schedule_message") }}
      </button>
      <el-button
        style="padding-top: 1.1em; padding-bottom: 1.1em"
        type="primary"
        :loading="loading"
        @click="sendMessage"
        v-if="fileUploadSuccess && fromVoice">
        {{ $t("dashboard.send_message.sms.quick_sms.form.button") }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import { read, utils } from "xlsx";

  export default {
    name: "send-simple-message",
    props: {
      payloadData: {
        type: Object,
      },
    },
    data() {
      return {
        showFileUploader: false,
        fileUploadSuccess: false,
        fileList: [],
        totalNumberOfRecipient: 0,
        campaign_name: "",
        audio: [],
        fromVoice: false,
        loading: false,
        errors: "",
        voice_template_id: "",
        headers: [],
        displayedHeaders: [],
        excelSheet: [],
        header: "",
        checked: true,
        phoneNumberRow: "",
        fakeColums: [
          "Column A",
          "Column B",
          "Column C",
          "Column D",
          "Column E",
          "Column F",
          "Column G",
          "Column H",
          "Column I",
          "Column J",
          "Column K",
          "Column L",
          "Column M",
          "Column N",
          "Column O",
          "Column P",
          "Column Q",
          "Column R",
          "Column S",
          "Column T",
          "Column U",
          "Column V",
          "Column W",
          "Column X",
          "Column Y",
          "Column Z",
        ],
        sheetsFound: [],
        allExcelData: [],
      };
    },
    computed: {
      fromExcel() {
        return store.state.modal.fromExcel;
      },
      hideHeaders() {
        return store.getters["uistate/contactForm"].fromVoice;
      },
      quickVoice() {
        return store.getters["uistate/contactForm"].fromVoice;
      },
      checkPageHeaders() {
        this.displayedHeaders;
      },
      checkSheetLength() {
        return this.sheetsFound.length > 1 ? true : false;
      },
    },
    mounted() {
      let isFromvoice = store.getters["uistate/fromVoice"];
      const formDataFromStore = store.state.modal.formData;
      if (formDataFromStore.excel_contacts.length) {
        this.totalNumberOfRecipient = formDataFromStore.totalNumberOfRecipient;
        this.headers = formDataFromStore.headers;
        this.displayedHeaders = formDataFromStore.headers;
        this.header = formDataFromStore.header;
        this.fileList = formDataFromStore.excel_contacts;
      }
      let fromExcel = store.state.modal.fromExcel;
      if (fromExcel) {
        this.showFileUploader = true;
        this.fileUploadSuccess = true;
      }
      if (isFromvoice) {
        this.fromVoice = true;
      }
      if (this.payloadData) {
        this.campaign_name = this.payloadData.campaign_name;
        this.audio = this.payloadData.audio;
        this.voice_template_id = this.payloadData.voice_template_id;
      }
    },
    methods: {
      showFileUpload() {
        store.dispatch("modal/setFromExcel", true);
        this.showFileUploader = true;
      },
      handleInputChange(value, key) {
        store.dispatch("modal/updateSingleField", {
          key,
          value,
        });
      },
      handleDisplayHeaders() {
        this.checkHeader();
      },
      checkHeader() {
        if (!this.checked) {
          this.displayedHeaders = this.fakeColums.slice(0, this.headers.length);
          // TODO: VALIDATE PHONE NUMBER CONTENT
        } else {
          this.displayedHeaders = this.headers;
        }
        this.header = this.displayedHeaders[0];
      },
      sheduleMessage() {
        let newFormData = {
          excel_contacts: this.fileList,
          voice_template_id: this.voice_template_id,
        };
        this.$emit("scheduleVoiceMessage", newFormData);
        store.dispatch("uistate/addContactFormData", {
          title: this.$t(
            "dashboard.send_message.scheduled_messages.schedule_message"
          ),
          type: "calender",
          voiceFrom: "excel",
        });
      },
      sendMessage(file) {
        const formDataFromStore = store.state.modal.formData;
        (this.loading = true), (this.errors = null);
        let formData = new FormData();
        if (this.voice_template_id) {
          formData.append("voice_template_id", this.voice_template_id);
        } else {
          formData.append("audio", this.audio);
        }
        formData.append("campaign_name", this.campaign_name);
        formData.append("excel_file", this.fileList[0]);
        formData.append("phone_number_column", formDataFromStore.header);
        store
          .dispatch("voice/quickExcel", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              res.data.from = "excel";
              if (this.voice_template_id) {
                res.data.voiceSent = true;
              }
              this.$emit("saveVoiceMessage", res.data);
              this.$mixpanel.track("Send Excel Voice Message");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Excel Voice Message");

              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Excel Voice Message");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Excel Voice Message"
                );
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = this.$t("misc.network_error");
            }
            if (err.response.status == 404) {
              this.errors = err.response.data.message;
            }
          });
      },
      async goToTypeMessage() {
        const sum = this.allExcelData.reduce((accumulator, array) => {
          return accumulator + array.length;
        }, 0);
        this.$emit("goToTypeMessage", {
          totalNumberOfRecipient:
            this.allExcelData.length > 1 ? sum : this.excelSheet.length,
          excel_contacts: this.fileList[0],
          headers: this.displayedHeaders,
          header: this.header,
          from: "excel",
        });

        store.dispatch("modal/addFormData", {
          currentPage: "",
          nextPage: "",
          prevPage: "",
          formData: {
            totalNumberOfRecipient:
              this.allExcelData.length > 1 ? sum : this.excelSheet.length,
            excel_contacts: this.fileList,
            headers: this.displayedHeaders,
            header: this.header,
            from: "excel",
          },
        });
      },
      handleFileExceed() {
        this.$message.error(
          this.$t("dashboard.send_message.sms.excel_sms.note.min_file")
        );
      },
      async handleChange(file, fileList) {
        this.fileList = [file.raw];
        this.errors = "";

        const f = await file.raw.arrayBuffer();
        const wb = read(f);
        this.sheetsFound = wb.SheetNames;

        // loop through wb and write all to this.allExcelData so we can find total number of recipients
        if (wb.SheetNames.length > 1) {
          for (let i = 0; i < wb.SheetNames.length; i++) {
            const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], {
              raw: false,
            });
            this.allExcelData.push(data);
          }
        }

        const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          raw: false,
        });

        this.excelSheet = data;
        if (!this.excelSheet.length) {
          this.errors = this.$t(
            "dashboard.send_message.sms.excel_sms.note.min_two"
          );
          return;
        }
        this.headers = Object.keys(this.excelSheet[0]);
        this.checkHeader();
        this.fileUploadSuccess = true;
      },
      cancelModal() {
        this.errors = "";
        if (store.getters["uistate/quickVoice"]) {
          return store.dispatch("uistate/addContactFormData", {
            title: this.$t("dashboard.send_message.select_sender_id"),
            type: "selectSenderId",
            quickVoice: true,
          });
        }
        this.$emit("backToSelect");
      },
    },
  };
</script>
<style scoped>
  @import url("../../../styles/__importexcel.scss");

  .el-upload__text {
    width: 100%;
    height: 230px;
  }

  .__margin {
    margin-right: 5px;
    margin-left: 5px;
  }

  .excel-container {
    width: 80%;
  }

  .excel-container .avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 376px) {
    .excel-container {
      overflow: hidden;
    }
  }

  .example-table * {
    padding: 0.2rem;
  }

  .note-excel {
    color: red;
  }

  .excel-header-example {
    background-color: #1c6c40;
    color: white;
    font-weight: 600;
  }

  .warning-excel-sheet {
    display: flex;
    font-size: smaller;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: red;
  }
</style>
