<template>
  <div
    class="row justify-content-center"
    style="padding-right: 1em; padding-left: 1em">
    <div class="col-md-12">
      <div class="__message__header" v-if="!errors">
        <p class="wallet-alert" v-if="messageType == 4">
          {{ $t("dashboard.send_message.confirmation_message.international") }}
        </p>
        <h2 class="__message__header_text">
          {{ $t("dashboard.send_message.confirmation_message.title") }}
        </h2>
        <p class="_message_tile notranslate">
          {{ userData.campaign_name ? userData.campaign_name : userData.title }}
        </p>
        <h2 class="__message__header_text" v-if="userData.message">
          {{ $t("dashboard.send_message.confirmation_message.body") }}
        </h2>
        <p class="_message_tile notranslate">
          {{ userData.message }}
        </p>
      </div>
      <div class="message__description__ mb-3" v-if="!errors">
        <p class="__credits pt-4" v-if="userData.route_name == 'standard'">
          <strong>{{
            userData.totalNumberOfRecipient * userData.perMessage
          }}</strong>
          {{ $t("dashboard.send_message.confirmation_message.sms_used") }}
        </p>
        <p class="characters">
          <strong>{{
            userData.message ? userData.message.length : userData.body.length
          }}</strong>
          {{ $t("dashboard.send_message.confirmation_message.characters") }}
        </p>
        <p class="recipients">
          <strong>{{ userData.totalNumberOfRecipient }}</strong>
          {{ $t("dashboard.send_message.confirmation_message.recipients") }}
        </p>
        <p>
          {{
            $t(
              "dashboard.send_message.message_templates.form.message.encoding_type"
            )
          }}
          <strong v-if="getEncoding == 'GSM_7BIT'" style="color: #36d962"
            >GSM 7BIT</strong
          >
          <strong
            v-else-if="getEncoding == 'GSM_7BIT_EX'"
            style="color: #36d962"
            >GSM 7BIT</strong
          >
          <strong v-else style="color: red">{{ getEncoding }} </strong>
        </p>
        <!-- <p class="selected-route">
          Selected Route:
          <strong v-if="userData.route_name"
            :class="{
              premium: userData.route_name == 'high_priority_route',
            }"
            >{{ userData.route_name.toUpperCase().replaceAll("_"," ") }}</strong
          >
        </p> -->
      </div>
      <div v-if="errors" class="mt-4">
        <Alert :message="errors" :type="'alert-danger'" />
      </div>
    </div>
    <div
      class="mt-3 mb-4 col-md-12 d-flex justify-content-between"
      style="width: 100%">
      <button class="___back__btn" @click="cancelModal">
        {{ $t("misc.button.back") }}
      </button>
      <button
        class="___back__btn"
        v-if="ShowScheduleButton == true"
        @click="ShowSchedule">
        {{ $t("dashboard.send_message.scheduled_messages.schedule_message") }}
      </button>
      <el-button
        v-if="!errors"
        :loading="loading"
        style="padding-right: 2em; color: #fff; padding-left: 2em"
        type="primary"
        @click="SendMessage"
        >{{
          userData.date_time ? "Schedule Messsage" : "Send Messsage"
        }}</el-button
      >
      <el-button v-if="errors" @click="closeMainModal" type="danger">{{
        $t("misc.button.close")
      }}</el-button>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import calender from "./calender.vue";
  import get_length_and_segment from "@/helpers/character_counter";
  import $ from "jquery";
  export default {
    props: {
      userData: {
        required: true,
        type: Object,
      },
      messageType: {
        type: Number,
        required: false,
      },
    },
    components: {
      calender: calender,
    },
    data() {
      return {
        loading: false,
        errors: "",
        success: "",
      };
    },
    name: "confirm-message",
    methods: {
      closeMainModal() {
        this.$emit("closeMainModal");
      },
      cancelModal() {
        this.$emit("backFromComfirmation");
      },
      sendQuickSMS() {
        let {
          sender_id,
          recipients,
          recipients_format,
          message,
          campaign_name,
          date_time,
          is_repeated,
          repeated_period,
          route_name,
        } = this.userData;
        let payload = {
          sender_id: sender_id,
          recipients: recipients,
          recipients_format: recipients_format,
          message: message,
          campaign_name: campaign_name,
          route_name: route_name,
        };
        if (date_time) {
          payload.date_time = date_time;
        }

        if (is_repeated && repeated_period) {
          payload.is_repeated = is_repeated;
          payload.repeated_period = repeated_period;
        }

        store
          .dispatch("campaigns/sendQuickSMS", payload)
          .then((data) => {
            this.loading = false;
            this.$emit("successMessage", {
              data: data,
              askForFeedback: true,
              askForCategory: true,
            });
            if (this.userData.date_time) {
              this.$mixpanel.track("Send Scheduled Message SMS");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment(
                "User Send Scheduled Message SMS"
              );

              //  for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Scheduled Message SMS");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Scheduled Message SMS"
                );
              }
            } else {
              this.$mixpanel.track("Send Quick SMS");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Quick SMS");

              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Quick SMS");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment("User Send Quick SMS");
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = this.$t("misc.general_error");
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
            if (err.response.data.data.logout) {
              this.$store.dispatch("auth/logout").then(() => {
                this.$router.push("/signin").catch((err) => {
                  if (err.name !== "NavigationDuplicated") throw err;
                });
              });
              $("#messages").modal("hide");
            }
            this.$notify({
              title: this.$t("misc.toast.error"),
              message: err.response.data.message,
              type: "error",
              duration: err.response.data.data.logout ? 0 : 5000,
              showClose: err.response.data.data.logout ? false : true,
            });
          });
      },
      sendSMSToGroup() {
        let {
          sender_id,
          groupIDs,
          message,
          campaign_name,
          date_time,
          is_repeated,
          repeated_period,
        } = this.userData;
        let payload = {
          sender_id: sender_id,
          message: message,
          campaign_name: campaign_name,
          group_ids: groupIDs.toString(),
        };
        if (date_time) {
          payload.date_time = date_time;
        }
        // Check if is repeated exists
        if (is_repeated && repeated_period) {
          payload.is_repeated = is_repeated;
          payload.repeated_period = repeated_period;
        }

        store
          .dispatch("campaigns/sendGroupSMS", payload)
          .then((data) => {
            this.loading = false;
            this.$emit("successMessage", {
              data: data,
              askForFeedback: true,
              askForCategory: true,
            });
            // Send Mixpanel Data
            if (this.userData.date_time) {
              this.$mixpanel.track("Send Scheduled Message SMS");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment(
                "User Send Scheduled Message SMS"
              );
              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Scheduled Message SMS");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Scheduled Message SMS"
                );
              }
            } else {
              this.$mixpanel.track("Send Group SMS");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Group SMS");
              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Group SMS");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment("User Send Group SMS");
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = this.$t("misc.general_error");
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.message;
            }
            this.$notify({
              title: this.$t("misc.toast.error"),
              message: err.response.data.message,
              type: "error",
            });
          });
      },
      sendToImportedContats() {
        let {
          sender_id,
          message,
          campaign_name,
          date_time,
          excel_contacts,
          header,
          is_repeated,
          repeated_period,
        } = this.userData;
        let formData = new FormData();
        formData.append("sender_id", sender_id);
        formData.append("message", message);
        formData.append("phone_number_column", header);
        if (date_time) {
          formData.append("date_time", date_time);
        }
        // check if is_repeated exists
        if (is_repeated && repeated_period) {
          formData.append("is_repeated", is_repeated);
          formData.append("repeated_period", repeated_period);
        }
        formData.append("excel_contacts", excel_contacts);
        formData.append("campaign_name", campaign_name);

        store
          .dispatch("campaigns/sendExcelSMS", formData)
          .then((data) => {
            this.loading = false;
            this.$emit("successMessage", {
              data: data,
              askForFeedback: true,
              askForCategory: true,
            });
            // Send Mixpanel Data
            if (this.userData.date_time) {
              this.$mixpanel.track("Send Scheduled Message SMS");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment(
                "User Send Scheduled Message SMS"
              );

              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Scheduled Message SMS");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Scheduled Message SMS"
                );
              }
            } else {
              this.$mixpanel.track("Send Excel SMS");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Excel SMS");

              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Excel SMS");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment("User Send Excel SMS");
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = this.$t("misc.general_error");
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.message;
            }
            this.$notify({
              title: this.$t("misc.toast.error"),
              message: err.response.data.message,
              type: "error",
            });
          });
      },
      SendMessage() {
        this.loading = true;
        this.error = null;
        this.success = null;
        if (this.userData.from == "individual") {
          return this.sendQuickSMS();
        }
        if (this.userData.from == "group") {
          this.sendSMSToGroup();
        } else {
          this.sendToImportedContats();
        }
        //
      },
      opendModal({ message, key, type, deleteType, sendType }) {
        if (type === "delete") {
          if (deleteType && deleteType == "__scheduled") {
            this.dataPayload = {
              currentModal: "delete",
              selectedKey: key,
              selectedMessage: message,
              deleteType: "__scheduled",
              deleteMessage: this.$t(
                "dashboard.send_message.scheduled_messages.delete"
              ),
            };
          } else {
            this.dataPayload = {
              currentModal: "delete",
              selectedKey: key,
              selectedMessage: message,
            };
          }
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "delete",
          });
        }
        if (type === "send") {
          let payload = {
            id: message.campaign_id,
            body: message.message,
            title: message.campaign_name,
            date_time: message.date_time,
          };
          if (sendType && sendType == "__scheduled") {
            this.dataPayload = {
              currentModal: "send",
              selectedKey: key,
              selectedMessage: payload,
              messageType: "__scheduled",
            };
            store.dispatch("modal/setFromSchedule", true);
          } else {
            this.dataPayload = {
              currentModal: "send",
              selectedKey: key,
              selectedMessage: message,
            };
          }
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
          store.dispatch("modal/addFormData", {
            currentPage: "view",
            nextPage: "",
            prevPage: "",
            formData: {
              campaign_id: message.campaign_id,
              message: message.message,
              campaign_name: message.campaign_name,
              date_time: message.date_time,
              isEdit: true,
              sender_id: message.sender_id,
              pageTitle: "",
            },
          });
        }
        return this.showModal();
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      ShowSchedule() {
        this.$emit("scheduleMessage", this.userData);
        store.dispatch("uistate/addContactFormData", {
          title: "Schedule message",
          type: "calender",
        });
      },
    },
    computed: {
      getEncoding() {
        let { encoding } = get_length_and_segment(this.userData.message);

        this.userData.encoding = encoding;
        return this.userData.encoding;
      },
      ShowScheduleButton() {
        return store.getters["uistate/isShowScheduleButtonConfirm"];
      },
    },
  };
</script>
<style lang="scss" scoped>
  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  .__message__header {
    margin-top: 2em;
    padding-bottom: 1em;
    border-bottom: 1px solid #f3f3f4;
    .__message__header_text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #575761;
    }

    ._message_tile {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 22px;
      color: #575761;
    }
  }

  .message__description__ p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #575761;
  }

  .recipients {
    padding-bottom: 3em;
  }

  ._message_tile {
    overflow-x: auto;
  }

  .wallet-alert {
    color: red !important;
  }
  .premium {
    color: #f7921c;
  }
</style>
