var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.success ? _c('div', {
    staticClass: "row justify-content-center align-items-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em"
    }
  }, [_c('successMessage', {
    attrs: {
      "message": _vm.success
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideMainModal
    }
  }, [_vm._v("Close")])], 1) : _c('div', {
    staticClass: "row justify-content-center align-items-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em",
      "padding-top": "3em"
    }
  }, [_c('div', {
    staticClass: "continue_edit_header pb-2"
  }, [_vm._m(0), !_vm.payload.deleteType ? _c('h3', {
    staticClass: "continue_edit_header_title"
  }, [_vm._v(" Deleting Template will remove it permanently. ")]) : _c('h3', {
    staticClass: "continue_edit_header_title"
  }, [_vm._v(" " + _vm._s(_vm.payload.deleteMessage) + " ")])]), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "padding-left": "3em",
      "padding-right": "3em"
    }
  }, [_vm.errors ? _c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  }) : _vm._e()], 1)]), !_vm.success ? _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('div', {
    staticClass: "deleteWrapper"
  }, [_c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "loading": _vm.loading,
      "type": "danger"
    },
    on: {
      "click": _vm.deleteMessage
    }
  }, [_vm._v(" Delete ")]), _c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.hideMainModal
    }
  }, [_vm._v(" Cancel ")])], 1)]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mb-4"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/Delete.svg"),
      "height": "33px",
      "width": "31px",
      "alt": "delete icon"
    }
  })]);
}];
export { render, staticRenderFns };