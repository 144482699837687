var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('button', {
    staticClass: "calendar-cell",
    class: _vm.rootClasses,
    attrs: {
      "data-date": _vm.date.day
    }
  }, [_vm._v(_vm._s(_vm.date.day))]);
};
var staticRenderFns = [];
export { render, staticRenderFns };