export default {
  name: "CalendarCell",
  inheritAttrs: false,
  props: {
    date: {
      required: true,
      type: Object
    },
    selectedDates: {
      type: Array
    },
    isSelected: {
      default: false,
      type: Boolean
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isBetween: {
      default: false,
      type: Boolean
    },
    isOneDayBefore: {
      default: false,
      type: Boolean
    },
    isOneDayAfter: {
      default: false,
      type: Boolean
    },
    isLast: {
      default: false,
      type: Boolean
    },
    isFirst: {
      default: false,
      type: Boolean
    },
    isOneDayBeforeFirst: {
      default: false,
      type: Boolean
    },
    isOneDayBeforeLast: {
      default: false,
      type: Boolean
    },
    isToday: {
      default: false,
      type: Boolean
    }
  },
  methods: {},
  computed: {
    rootClasses() {
      return {
        "--is-other-month-day": this.date.isOtherMonthDay,
        "--selected": this.isSelected,
        "--weekend": this.date.isWeekend,
        // "--disabled": this.isDisabled,
        "--isToday": this.date.isToday,
        "--between": this.isBetween,
        "--first": this.isFirst,
        "--last": this.isLast,
        "--one-day-before": this.isOneDayBefore,
        "--one-day-after": this.isOneDayAfter,
        "--one-day-before-first": this.isOneDayBeforeFirst,
        "--one-day-before-last": this.isOneDayBeforeLast
      };
    }
  }
};