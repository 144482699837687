import store from "@/state/store.js";
import Storage from "@/services/storage.js";
import get_length_and_segment from "@/helpers/character_counter";
import _ from "lodash";
import SendToGroup from "./sendToGroup.vue";
import ASCIIFolder from "fold-to-ascii";
import selectRoute from "./global/selectRoute.vue";
export default {
  name: "send-simple-message",
  components: {
    selectRoute
  },
  props: {
    payloadData: {
      type: Object,
      required: true
    }
    // SendToGroup
  },
  data() {
    return {
      type: "alert-danger",
      messages: "",
      success: "",
      errors: "",
      modalType: "",
      loading: false,
      rules: {
        sender_id: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        campaign_name: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        message: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }, {
          max: 459,
          message: this.$t("dashboard.send_message.message_templates.form.message.reduce_chars"),
          trigger: ["change", "blur"]
        }],
        sender: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }, {
          max: 11,
          message: this.$t("dashboard.sender_id.min"),
          trigger: ["change", "blur"]
        }],
        purpose: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        route_name: [{
          required: false,
          message: this.$t("misc.form.required"),
          trigger: "blur"
        }]
      },
      formData: {
        campaign_name: "",
        message: "",
        sender_id: "",
        perMessage: 0,
        campaign_id: "",
        purpose: "",
        sender: "",
        headers: [],
        header: "",
        messageWithTitle: "",
        route_name: ""
      },
      sendMessage: {},
      sendMessageRules: {},
      selectedKey: "",
      isEdit: false,
      selectedKey: "",
      createSenderID: false,
      openSenderIDError: "",
      encodingType: "",
      groupOnly: false,
      useGSMOnly: true,
      showGSM_Only_Info: false
    };
  },
  mounted() {
    let editedData = store.getters["uistate/contactForm"];
    const getFormData = store.getters["modal/getFormData"];
    if (getFormData.campaign_name) {
      this.formData = {
        campaign_name: getFormData.campaign_name,
        message: getFormData.message,
        sender_id: getFormData.sender_id,
        campaign_id: getFormData.campaign_id
      };
    }
    if (editedData.isEdit) {
      this.selectedKey = editedData.editData.selectedKey;
      this.isEdit = true;
    }
  },
  computed: {
    corporate() {
      const storage = new Storage();
      return storage.getSavedState("isCorporate");
    },
    messageForm() {
      return store.getters["uistate/contactForm"];
    },
    senderIDs() {
      return store.getters["senderid/allSenderIDs"];
    },
    findNumberOfRecipient() {
      let {
        message
      } = this.formData;
      if (message.length == 0) {
        return 0;
      }
      let {
        messages,
        encoding
      } = get_length_and_segment(message);
      this.formData.perMessage = messages;
      this.formData.encoding = encoding;
      this.encodingType = encoding;
      return messages;
    },
    checkMessageScheduled() {
      return store.state.uistate.isScheduledType;
    },
    getEncoding() {
      return this.encodingType;
    },
    checkIfExcel() {
      var _this$payloadData$exc;
      return (_this$payloadData$exc = this.payloadData.excel_contacts) === null || _this$payloadData$exc === void 0 ? void 0 : _this$payloadData$exc.status;
    },
    checkIfGroup() {
      let isGroup = store.getters["modal/messageAction"];
      if (isGroup == "group") {
        return true;
      }
    },
    formatASCII: {
      get() {
        return this.formData.message;
      },
      set(value) {
        if (this.useGSMOnly) {
          this.formData.message = ASCIIFolder.foldReplacing(value);
        } else {
          this.formData.message = value;
        }
      }
    },
    dynamicModalWidth() {
      if (screen.width < 768) {
        return "98%";
      } else {
        return "50%";
      }
    }
  },
  methods: {
    handleInputChange(value, key) {
      store.dispatch("modal/updateSingleField", {
        key,
        value
      });
    },
    insertText(data) {
      // insert at cursor position
      var textarea = document.getElementById("simpleTextArea");
      var strPos = 0;
      var br = textarea.selectionStart || textarea.selectionStart == "0" ? "ff" : document.selection ? "ie" : false;
      if (br == "ie") {
        textarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -textarea.value.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = textarea.selectionStart;
      var front = textarea.value.substring(0, strPos);
      var back = textarea.value.substring(strPos, textarea.value.length);
      this.formData.message = front + data + back;
      strPos = strPos + data.length;
      if (br == "ie") {
        textarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -textarea.value.length);
        range.moveStart("character", strPos);
        range.moveEnd("character", 0);
        range.select();
      } else if (br == "ff") {
        textarea.selectionStart = strPos;
        textarea.selectionEnd = strPos;
        textarea.focus();
      }
      this.$refs.message.focus();
    },
    submit(formName, type) {
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (type == "createSenderID") {
            return this.createNewSenderID();
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    createNewSenderID() {
      this.openSenderIDError = "";
      this.loading = true;
      let payload = {
        sender_id: this.formData.sender,
        purpose: this.formData.purpose
      };
      store.dispatch("senderid/registerSenderID", payload).then(response => {
        const sender = response.data.data;
        if (sender.status == 1) {
          this.formData.sender_id = sender.id;
        }
        if (response) {
          this.loading = false;
          this.createSenderID = false;
        }
        if (response.data.data.status == 0) {
          this.$notify({
            title: this.$t("misc.toast.alert"),
            message: this.$t("dashboard.sender_id.contact_admin"),
            type: "alert"
          });
        }
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.openSenderIDError = this.$t("misc.network_error");
        }
        if (err.response && err.response.status === 404) {
          this.openSenderIDError = err.response.data.data.error;
        }
      });
    },
    cancelModal() {
      let editedData = store.getters["uistate/contactForm"];
      this.$emit("cancelMessageModal", editedData.isEdit);
    },
    editMessage() {
      this.loading = true;
      store.dispatch("campaigns/editScheduledMessages", this.formData).then(response => {
        this.loading = false;
        let payload = {
          data: response.data.data,
          key: this.selectedKey,
          message: response.data.message
        };
        this.$emit("editTemplate", payload);
      }).catch(err => {
        this.loading = false;
        if (err.response.data == undefined) {
          this.errors = this.$t("misc.general_error");
        }
        if (err.response && err.response.status === 404) {
          this.errors = err.response.data.message;
        } else {
          this.$notify({
            title: this.$t("misc.toast.error"),
            message: err.response.data.message,
            type: "error"
          });
        }
      });
    },
    sendMessageTo(formName, type) {
      if (this.payloadData && this.payloadData.header) {
        this.formData.headers = this.payloadData.headers;
        this.formData.header = this.payloadData.header;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (type == "send") {
            this.$emit("sendSimpleData", this.formData);
          } else if (type === "schedule") {
            this.$emit("scheduleMessage", this.formData);
          } else {
            // make api request here
            this.editMessage();
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  }
};