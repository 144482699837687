import storage from "@/services/storage.js";
import apiService from "@/services/api.service";
export default {
  name: "selectRoute",
  emits: ["routeValue"],
  props: {
    inputSize: {
      type: String,
      default: "medium"
    },
    showHelp: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      routeOptions: [{
        value: "standard",
        label: "Standard"
      }, {
        value: "high_priority_route",
        label: "High Priority"
      }],
      storage: new storage(),
      selectedRoute: "standard",
      helpVisible: false
    };
  },
  methods: {
    async onRouteChange() {
      this.$emit("input", this.selectedRoute);
      this.storage.saveAndDelete("defaultRoute", this.selectedRoute);
      // save route
      try {
        await apiService.post(`api/save/default/route`, {
          route_name: this.selectedRoute
        });
      } catch (error) {
        console.error(error);
      }
    }
  },
  async mounted() {
    if (this.storage.getSavedState("defaultRoute")) {
      this.selectedRoute = this.storage.getSavedState("defaultRoute");
      this.$emit("input", this.selectedRoute);
      return;
    }
    try {
      const {
        data
      } = await apiService.get(`api/get/default/route`);
      this.selectedRoute = (data === null || data === void 0 ? void 0 : data.route_name) || "standard";
      this.$emit("input", this.selectedRoute);
    } catch (error) {
      console.error(error);
    }
  }
};