import store from '@/state/store.js';
import loginType from '@/helpers/loginType.js';
export default {
  name: 'view-message',
  props: {
    payload: {
      required: true,
      type: Object
    },
    sendStraightToSenderId: {
      required: false,
      type: Function,
      default: false
    }
  },
  data() {
    return {
      showEditInModal: true,
      goStraightToEditValue: false,
      showSenderID: false,
      rules: {
        sender_id: [{
          required: true,
          message: 'Sender ID is required',
          trigger: 'change'
        }]
      },
      formData: {
        sender_id: "",
        perMessage: 0
      },
      sendToSenderID: this.sendStraightToSenderId
    };
  },
  computed: {
    senderIDs() {
      return store.getters['senderid/senderIDs'];
    },
    senderIdFromStore() {
      return store.state.modal.formData.sender_id;
    },
    setStraightToEdit() {
      this.goStraightToEditValue = this.$props.payload.goStraightToEdit;
    },
    showEditButtonVoice() {
      return loginType() === 'voice_messages' && this.$props.payload.messageType === '__scheduled';
    }
  },
  methods: {
    handleInputChange(value, key) {
      store.dispatch('modal/updateSingleField', {
        key,
        value
      });
      this.formData.sender_id = value;
    },
    hideMainModal() {
      if (this.showSenderID) {
        this.showSenderID = false;
        return;
      }
      store.dispatch('uistate/setIsInternational', false);
      this.$emit('closeModal');
    },
    scheduledMessage() {
      return store.dispatch('uistate/addContactFormData', {
        title: "Reschedule message",
        type: 'calender',
        isEdit: true,
        editType: "editMessage",
        editData: {
          selectedKey: this.payload.selectedKey,
          selectedMessage: this.payload.selectedMessage
        }
      });
    },
    editTemplate() {
      const newFormData = store.state.modal.formData;
      if (this.payload.messageType == '__scheduled') {
        store.dispatch('uistate/addContactFormData', {
          title: "Edit Message",
          type: 'sendMessage',
          isEdit: true,
          editData: {
            selectedKey: this.payload.selectedKey,
            selectedMessage: this.payload.selectedMessage
          }
        });
        if (!newFormData.title && !newFormData.message) {
          store.dispatch('modal/addFormData', {
            currentPage: 'sendMessage',
            nextPage: '',
            prevPage: '',
            formData: {
              id: this.payload.selectedMessage.id,
              body: this.payload.selectedMessage.body,
              title: this.payload.selectedMessage.title,
              type: this.payload.selectedMessage.type,
              isEdit: true,
              pageTitle: 'Edit Message',
              selectedKey: this.payload.selectedKey
            }
          });
        }
      } else {
        store.dispatch('uistate/addContactFormData', {
          title: "Edit template",
          type: 'template',
          isEdit: true,
          editData: {
            selectedKey: this.payload.selectedKey,
            selectedMessage: this.payload.selectedMessage
          }
        });
        if (!newFormData.title && !newFormData.message) {
          store.dispatch('modal/addFormData', {
            currentPage: 'template',
            nextPage: '',
            prevPage: '',
            formData: {
              id: this.payload.selectedMessage.id,
              body: this.payload.selectedMessage.body,
              title: this.payload.selectedMessage.title,
              type: this.payload.selectedMessage.type,
              isEdit: true,
              pageTitle: 'Edit template',
              selectedKey: this.payload.selectedKey
            }
          });
        }
      }
    },
    deleteTemplate() {
      let dataPayload = {
        currentModal: 'delete',
        selectedKey: this.payload.selectedKey,
        selectedMessage: this.payload.selectedMessage
      };
      this.$emit('deleteFromView', dataPayload);
      store.dispatch('uistate/addContactFormData', {
        title: "delete",
        type: 'delete'
      });
    },
    sendMessage(formName) {
      let message = this.payload.selectedMessage;
      if (message.body.length <= 160) {
        this.formData.perMessage = 1;
      } else {
        if (message.body.length > 160) {
          this.formData.perMessage = Math.ceil(message.body.length / 153);
        }
      }
      // else if (message.body.length > 160 && message.body.length <= 320) {
      //     this.formData.perMessage = 2
      // } else if (message.body.length > 320 && message.body.length <= 460) {
      //     this.formData.perMessage = 3
      // }
      store.dispatch('modal/setFromView', true);
      //prepare payload 
      let payload = {
        campaign_name: message.title,
        message: message.body,
        sender_id: this.formData.sender_id,
        perMessage: this.formData.perMessage
      };
      this.$refs[formName].validate(valid => {
        if (valid || this.senderIdFromStore) {
          this.$emit('goToSelectUsers', payload);
        } else {
          return false;
        }
      });
    },
    checkIfInternational() {
      if (this.$props.payload.selectedMessage.type == 4) {
        let message = this.payload.selectedMessage;
        store.dispatch('modal/setFromView', true);
        //prepare payload 
        let payload = {
          campaign_name: message.title,
          message: message.body,
          sender_id: message.sender_id.id,
          perMessage: this.formData.perMessage
        };
        // Per SMS is not shown because calculations have not been done when international
        store.dispatch('uistate/setIsInternational', true);
        this.$emit('goToSelectUsers', payload);
      } else {
        return this.showSenderID = true;
      }
    }

    // checkMessageContent() {
    //     const getFormData = store.getters['modal/getFormData']
    //     if (getFormData.body.includes("[fname]", "[fullname]", "[lname]")) {
    //         store.dispatch('uistate/setIsFromTemplateWithSpecial', true)
    //     }
    // },
  },
  beforeMount() {
    if (store.state.uistate.contactForm && store.state.uistate.contactForm.fromSenderId) {
      this.showSenderID = true;
    }
  },
  watch: {
    setStraightToEdit: {
      immediate: true,
      deep: true,
      handler() {
        if (this.goStraightToEditValue == true) {
          this.editTemplate();
          this.$props.payload.goStraightToEdit = false;
          store.dispatch('uistate/setCancelDirectEdit', true);
        }
      }
    }
  }
};