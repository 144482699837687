<template>
  <div class="select-group-container">
    <div class="modal-header pt-4 pb-2">
      <h5 class="modal-title" id="exampleModalLabel">
        {{ $t("dashboard.send_message.sms.group_sms.select_group") }}
      </h5>
      <button class="select_all" v-if="!select_all" @click="selectAll">
        {{ $t("dashboard.send_message.sms.group_sms.select_all") }}
      </button>
      <button class="select_all deselect_all" @click="deselectAll" v-else>
        {{ $t("dashboard.send_message.sms.group_sms.deselect_all") }}
      </button>
    </div>
    <div class="modal-body m-0 p-0 main-select-group" v-loading="fetchingGroup">
      <div
        class="row justify-content-center pt-3"
        style="padding-right: 1em; padding-left: 1em">
        <div class="contains-group-list">
          <div
            v-if="groups.length == 0"
            style="display: flex; justify-content: center; align-items: center">
            {{ $t("dashboard.send_message.sms.group_sms.no_groups") }}
          </div>
          <div
            v-infinite-scroll="useInfiniteLoading"
            class="col-md-12 align-items-center infinite-list">
            <button
              v-for="(group, key) in groups"
              :key="key"
              class="_btn"
              :class="[group.active == true ? 'active' : '']">
              <div class="d-flex pl-2 contains-check-box">
                <el-checkbox
                  @change="handleGroupChange($event, group, key)"
                  :value="group.active"
                  class="checkbox-element">
                </el-checkbox>
                <p class="notranslate">{{ group.name }}</p>
              </div>
              <div class="pr-3">
                <p>
                  {{ group.contact_count }}
                  {{ $t("dashboard.send_message.sms.group_sms.members") }}
                </p>
              </div>
            </button>
            <p class="loading-state" v-if="loadingGroupMembers">Loading...</p>
            <p
              class="loading-state"
              v-if="groups.length > 0 && groups.length == totalGroupMembers">
              {{ $t("dashboard.send_message.sms.group_sms.all_loaded") }}
            </p>
          </div>
        </div>
        <div class="col-md-12 mt-2" v-if="errors">
          <Alert :message="errors" :type="'alert-danger'" />
        </div>
        <div
          class="col-md-12 mt-5 mb-5 d-flex justify-content-between"
          style="width: 100%">
          <button class="___back__btn notranslate" @click="cancelModal">
            {{ $t("misc.button.back") }}
          </button>
          <div
            class="left_btn"
            v-if="Array.isArray(selectGroupIDs) && selectGroupIDs.length > 0">
            <el-button
              @click="goToTypeMessage"
              v-if="!fromVoice"
              style="padding-top: 1.1em; padding-bottom: 1.1em"
              type="primary">
              {{ $t("misc.button.send") }}
            </el-button>
            <button
              form="voice"
              v-if="fromVoice"
              class="schedule_message____"
              @click="sheduleVoiceMessage">
              {{
                $t("dashboard.send_message.scheduled_messages.schedule_message")
              }}
            </button>
            <el-button
              style="padding-top: 1.1em; padding-bottom: 1.1em"
              type="primary"
              :loading="loading"
              @click="sendMessage"
              v-if="fromVoice">
              {{
                $t("dashboard.send_message.sms.quick_sms.form.button")
              }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  export default {
    name: "send-to-group",
    props: {
      payload: {},
      payloadData: {},
    },
    data() {
      return {
        select_all: false,
        hovered: "",
        selectGroupIDs: [],
        groups: [],
        totalNumberOfRecipient: 0,
        fetchingGroup: false,
        fromVoice: false,
        campaign_name: "",
        audio: [],
        errors: "",
        loading: false,
        voice_template_id: "",
        page: 1,
        loadingGroupMembers: false,
        totalGroupMembers: 0,
      };
    },
    computed: {
      groupsIDFromStore() {
        return store.state.modal.formData.groupIDs;
      },
      fetchedGroups() {
        return store.getters["groups/all_groups"];
      },
    },
    mounted() {
      store.dispatch("uistate/setIsFromTemplateWithSpecial", false);
      this.fetchingGroup = true;
      store
        .dispatch("groups/listGroups", this.page)
        .then((response) => {
          this.fetchingGroup = false;
          let data = response.data.data.data;
          this.totalGroupMembers = response.data.data.total;
          if (data.length > 0) {
            data.forEach((group) => {
              if (this.groupsIDFromStore.includes(group.id)) {
                group.active = true;
                this.selectGroupIDs.push(group.id);
                this.totalNumberOfRecipient += group.contact_count;
              } else {
                group.active = false;
              }
            });
            this.groups = data;
          }
          this.page = response.data.data.current_page + 1;
        })
        .catch((err) => (this.fetchingGroup = false));

      let data = store.getters["uistate/contactForm"];
      let isFromvoice = store.getters["uistate/fromVoice"];
      if (isFromvoice) {
        this.fromVoice = true;
      }
      if (this.payloadData) {
        this.campaign_name = this.payloadData.campaign_name;
        this.audio = this.payloadData.audio;
        this.voice_template_id = this.payloadData.voice_template_id;
      }
    },
    methods: {
      handleGroupChange(e, group, key) {
        if (group.contact_count > 0) {
          if (!this.groups[key].active) {
            this.selectGroupIDs.push(group.id);
            this.totalNumberOfRecipient += group.contact_count;
          } else {
            let groupIndex = this.selectGroupIDs.indexOf(group.id);
            this.selectGroupIDs.splice(groupIndex, 1);
            if (this.totalNumberOfRecipient > 0) {
              this.totalNumberOfRecipient -= group.contact_count;
            }
          }
          //change group status
          this.groups[key].active = !this.groups[key].active;
          store.dispatch("modal/updateSingleField", {
            key: "groupIDs",
            value: this.selectGroupIDs,
          });
          store.dispatch("modal/updateSingleField", {
            key: "totalNumberOfRecipient",
            value: this.totalNumberOfRecipient,
          });
        } else {
          this.$notify({
            title: this.$t("misc.toast.warning"),
            message: this.$t("dashboard.send_message.sms.group_sms.no_contact"),
            type: "warning",
          });
        }
      },
      sheduleVoiceMessage() {
        let newFormData = {
          groupIDs: this.selectGroupIDs,
          voice_template_id: this.voice_template_id,
        };
        this.$emit("scheduleVoiceMessage", newFormData);
        store.dispatch("uistate/addContactFormData", {
          title: this.$t("dashboard.send_message.voice.quick_voice.schedule"),
          type: "calender",
          voiceFrom: "group",
        });
      },
      scheduleMessage() {
        store.dispatch("uistate/addContactFormData", {
          title: this.$t(
            "dashboard.send_message.scheduled_messages.schedule_message"
          ),
          type: "calender",
        });
      },
      sendMessage() {
        (this.loading = true), (this.errors = null);
        let formData = new FormData();
        formData.append("campaign_name", this.payloadData.campaign_name);
        if (this.payloadData.voice_template_id) {
          formData.append(
            "voice_template_id",
            this.payloadData.voice_template_id
          );
        } else {
          formData.append("audio", this.payloadData.audio);
        }
        formData.append("group_ids[]", JSON.stringify(this.selectGroupIDs));
        store
          .dispatch("voice/voiceGroup", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              res.data.from = "group";
              if (this.payloadData.voice_template_id) {
                res.data.voiceSent = true;
              }
              this.$emit("saveVoiceMessage", res.data);
              // Add mixpanel analytics
              this.$mixpanel.track("Send Group Voice Message");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Group Voice Message");
              // for franco
              if (store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Group Voice Message");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Group Voice Message"
                );
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error. try again.";
            }
            if (err.response.status == 404) {
              this.errors = err.response.data.message;
            }
          });
      },
      goToTypeMessage() {
        this.$emit("goToTypeMessage", {
          totalNumberOfRecipient: this.totalNumberOfRecipient,
          groupIDs: this.selectGroupIDs,
          from: "group",
        });
      },
      selectAll() {
        this.select_all = true;
        this.groups = this.groups.map((obj) => ({
          ...obj,
          active: obj.contact_count > 0 ? true : false,
        }));
        let totalContacts = 0;
        this.groups.forEach((group) => {
          if (group.contact_count > 0) {
            this.selectGroupIDs.push(group.id);
          }
        });
        this.groups.forEach(
          (group) => (totalContacts = totalContacts + group.contact_count)
        );
        this.totalNumberOfRecipient = totalContacts;
      },
      deselectAll() {
        this.select_all = false;
        this.groups = this.groups.map((obj) => ({ ...obj, active: false }));
        this.selectGroupIDs = [];
        this.totalNumberOfRecipient = 0;
      },
      handleAdd(group, key) {
        if (group.contact_count > 0) {
          this.groups[key].active = !this.groups[key].active;
        } else {
          this.$notify({
            title: this.$t("misc.toast.warning"),
            message: this.$t("dashboard.send_message.sms.group_sms.no_contact"),
            type: "warning",
          });
        }
        this.selectGroupIDs.push(group.id);
        this.totalNumberOfRecipient += group.contact_count;
      },
      handleRemove(group, key) {
        this.groups[key].active = !this.groups[key].active;
        let groupIndex = this.selectGroupIDs.indexOf(group.id);
        this.selectGroupIDs.splice(groupIndex, 1);
        if (this.totalNumberOfRecipient > 0) {
          this.totalNumberOfRecipient -= group.contact_count;
        }
      },
      cancelModal() {
        if (store.getters["uistate/quickVoice"]) {
          return store.dispatch("uistate/addContactFormData", {
            title: this.$t("dashboard.send_message.select_sender_id"),
            type: "selectSenderId",
            quickVoice: true,
          });
        }
        this.$emit("backToSelect");
      },
      useInfiniteLoading() {
        if (this.loadingGroupMembers) {
          return;
        }

        this.loadingGroupMembers = true;
        if (this.groups.length === this.totalGroupMembers) {
          this.loadingGroupMembers = false;
          return;
        }

        // Increment this.page only if there is more data to load
        if (this.groups.length < this.totalGroupMembers) {
          store
            .dispatch("groups/listGroups", this.page)
            .then((response) => {
              let data = response.data.data.data;

              // Filter out duplicates before appending
              data = data.filter(
                (group) =>
                  !this.groups.find(
                    (existingGroup) => existingGroup.id === group.id
                  )
              );

              if (data.length > 0) {
                data.forEach((group) => {
                  if (this.groupsIDFromStore.includes(group.id)) {
                    group.active = true;
                    this.selectGroupIDs.push(group.id);
                    this.totalNumberOfRecipient += group.contact_count;
                  } else {
                    group.active = false;
                  }
                });

                // Append filtered data to this.groups
                this.groups = [...this.groups, ...data];
              }

              // Increment this.page here
              this.page = response.data.data.current_page + 1;
            })
            .finally(() => {
              this.loadingGroupMembers = false;
            });
        }
      },
    },
  };
</script>

<style scoped>
  .modal-dialog {
    overflow-y: initial;
  }

  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }

  .__header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #484a4f;
  }

  ._btn {
    background: #f5f5f5;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9e9ea7;
    outline: none;
    border: none;
    margin-top: 0.5em;
  }

  ._btn:hover,
  .active {
    background-color: rgba(109, 34, 242, 0.1) !important;
    color: #f7921c !important;
  }

  ._btn span {
    background: url("../../../assets/checkbox.svg");
    width: 17px;
    height: 17px;
    display: inline-block;
  }

  ._btn:hover span {
    background: url("../../../assets/active_checkbox.png");
    width: 17px;
    height: 17px;
    display: inline-block;
  }

  .icon_btn {
    outline: none;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-color: transparent;
    margin-top: 0.2em;
    border: none;
  }

  p {
    margin-bottom: 0px;
    padding-left: 1em;
  }

  .select_all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 40px;
    background: #f6f6f6;
    border-radius: 44px;
    color: #484a4f;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    outline: none;
    border: none;
  }

  .deselect_all {
    background-color: #f7921c;
    color: #fff;
  }

  .select-group-container * {
    width: 100;
    overflow-x: hidden;
  }

  .contains-check-box {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkbox-element {
    margin: auto 0;
  }

  .contains-group-list {
    width: 100%;
    height: 55vh;
    overflow-y: scroll;
  }

  .loading-state {
    text-align: center;
    font-size: small;
    padding-top: 10px;
  }
</style>
