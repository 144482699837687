import store from "@/state/store.js";
export default {
  name: "select-sender-id",
  data() {
    return {
      hovered: "",
      fromVoice: false,
      isFromQuickVoice: false,
      voiceData: {
        campaign_name: "",
        audio: ""
      },
      showQuickSMS: true,
      showModal: false,
      showConfirmationModal: false,
      buttonLoading: false,
      isConfirmationDialogVisible: false
    };
  },
  mounted() {
    let isFromvoice = store.getters["uistate/fromVoice"];
    let isFromQuickVoice = store.getters["uistate/quickVoice"];
    if (isFromQuickVoice) {
      this.isFromQuickVoice = true;
    }
    if (isFromvoice) {
      this.fromVoice = true;
    }
    this.checkToggleShowOptions();
  },
  computed: {
    messageAction() {
      if (store.getters["uistate/getisFromTemplateWithSpecial"] === true) {
        this.sendToGroup();
        store.dispatch[("uistate/getisFromTemplateWithSpecial", false)];
      }
      return store.state.modal.messageAction;
    },
    fromView() {
      return store.state.modal.fromView;
    },
    checkIsVoice() {
      let isFromvoice = store.getters["uistate/fromVoice"];
      let isFromQuickVoice = store.getters["uistate/quickVoice"];
      return isFromvoice || isFromQuickVoice ? true : false;
    },
    title() {
      return this.checkIsVoice ? this.$t("dashboard.send_message.select") : this.$t("dashboard.send_message.what_action");
    },
    groupTitle() {
      return this.checkIsVoice ? this.$t("dashboard.send_message.voice.group_voice.title") : this.$t("dashboard.send_message.sms.group_sms.title");
    },
    individual() {
      return this.checkIsVoice ? this.$t("dashboard.send_message.voice.quick_voice.title") : this.$t("dashboard.send_message.sms.quick_sms.title");
    },
    contacts() {
      return this.checkIsVoice ? this.$t("dashboard.send_message.voice.excel_voice.title") : this.$t("dashboard.send_message.sms.excel_sms.title");
    },
    fromSelectSenderIDForVoice() {
      return store.getters["uistate/contactForm"].fromSelectSenderIDForVoice ? true : false;
    },
    isSpecialRoute() {
      return this.$route.path === "/voice/messages";
    }
  },
  methods: {
    emitFormData(data) {
      this.$emit("goToSelectUsers", data);
    },
    cancelModal() {
      if (this.fromView) {
        store.dispatch("modal/setFromView", false);
        if (store.getters["uistate/isInternational"]) {
          return store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
            fromSenderId: false
          });
        } else {
          return store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
            fromSenderId: true
          });
        }
      }
      this.$emit("cancelModal", {
        type: this.fromVoice ? "fromVoice" : "cancel"
      });
    },
    checkVoiceData() {
      let data = store.getters["uistate/contactForm"];
      if (data.message && data.message.isScheduleVoice) {
        delete data.message.isScheduleVoice;
        this.$emit("scheduleVoiceMessage", data.message);
      }
    },
    sendToGroup() {
      this.checkVoiceData();
      store.dispatch("uistate/addContactFormData", {
        title: this.$t("dashboard.send_message.message_templates.send_message"),
        type: "selectGroup",
        fromVoice: this.fromVoice,
        quickVoice: this.isFromQuickVoice ? true : false
      });
      store.dispatch("modal/setMessagAction", "group");
      store.dispatch("uistate/setIsFromTemplateWithSpecial", false);
    },
    importContacts() {
      this.checkVoiceData();
      store.dispatch("uistate/addContactFormData", {
        title: this.$t("dashboard.send_message.sms.excel_sms.header"),
        type: "importContacts",
        fromVoice: this.fromVoice,
        quickVoice: this.isFromQuickVoice ? true : false
      });
      store.dispatch("modal/setMessagAction", "excel");
    },
    sendToAnIndividual() {
      this.checkVoiceData();
      store.dispatch("uistate/addContactFormData", {
        title: this.fromVoice ? this.$t("dashboard.send_message.voice.quick_voice.title") : this.$t("dashboard.send_message.sms.quick_sms.title"),
        type: "selectIndividual",
        fromVoice: this.fromVoice,
        quickVoice: this.isFromQuickVoice ? true : false
      });
      store.dispatch("modal/setMessagAction", "individual");
    },
    checkToggleShowOptions() {
      const getFormData = store.getters["modal/getFormData"];
      const pattern = /\[(fname|lname|fullname)\]/;
      if (pattern.exec(getFormData.body)) {
        this.showQuickSMS = false;
        store.dispatch("uistate/setIsFromTemplateWithSpecial", true);
      }
    },
    ConfirmationDialogVisible() {
      this.isConfirmationDialogVisible = true;
    },
    submitChange() {
      this.buttonLoading = true;
      this.$store.dispatch("voice/requestDid").then(() => {
        this.$message({
          type: "success",
          message: this.$t("dashboard.send_message.sms.DID_success")
        });
        this.isConfirmationDialogVisible = false;
        this.$emit("cancelModal");
      }).catch(error => {
        this.$message({
          type: "error",
          message: this.$t("dashboard.send_message.sms.DID_error")
        });
      }).finally(() => {
        this.buttonLoading = false;
      });
    }
  }
};