<template>
  <div class="calender">
    <div
      class="row justify-content-center mb-5 align-items-center main-modal-form-calendar">
      <div class="col-md-12">
        <div class="__title">
          <p class="__top__text">pick a date and time to schedule message</p>
        </div>
        <div class="__main__calender">
          <Callender
            :defaultDateFromApi="selectedDate"
            @handleDate="handleDate"
            class="calendar-object" />
          <div class="text-center" v-if="error">
            <small class="text-danger text-center">Please Select date</small>
          </div>
        </div>
        <div
          class="_display_message mx-3 d-flex justify-content-center align-items-center mt-4 separator-container">
          <el-form
            :rules="rules"
            :model="formData"
            ref="ruleForm"
            @submit.prevent.native="checkEnter"
            class="main-calender">
            <div class="__time__inputs">
              <div class="__hour">
                <el-form-item prop="hour">
                  <el-select
                    class="hour"
                    v-model="formData.hour"
                    filterable
                    placeholder="00">
                    <el-option
                      v-for="(h, key) in computedHours"
                      :key="key"
                      :label="h"
                      :value="h">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="__separator">:</div>
              <div class="__minute">
                <el-form-item prop="minute">
                  <el-select
                    class="__select"
                    v-model="formData.minute"
                    filterable
                    placeholder="00">
                    <el-option
                      v-for="(h, key) in minutes"
                      :key="key"
                      :label="h"
                      :value="h">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="__timeframe">
                <!-- AM/PM -->
                <el-form-item prop="period" v-if="use12Hour" required>
                  <el-select
                    class="__select"
                    v-model="formData.am_pm"
                    value="AM"
                    filterable
                    placeholder="AM">
                    <el-option value="AM" :key="'AM'">AM</el-option>
                    <el-option value="PM" :key="'PM'">PM</el-option>
                  </el-select>
                </el-form-item>
              </div>

              <!-- <div class="__timeframe">
                                <el-form-item prop="period">
                                    <el-select class="__select" v-model="formData.period" filterable placeholder="00">
                                        <el-option v-for="(h, key) in minutes" :key="key" :label="h" :value="h">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div> -->
            </div>
            <div class="contains-checkbox">
              <el-checkbox v-model="use12Hour">Use 12 Hour Format</el-checkbox>
              <el-checkbox v-model="formData.is_repeated"
                >Repeat Scheduling</el-checkbox
              >
            </div>
          </el-form>
        </div>
        <div class="col-md-12 contains-date" v-if="selectedDate">
          <div class="selected_date mx-2">
            <p class="_seletedDate_text">
              {{
                selectedDate
                  ? `${moment(selectedDate, "YYYY-MM-DD h:mm:ss a").format(
                      "dddd"
                    )},
                                                        ${moment(
                                                          selectedDate
                                                        ).format("LL")}`
                  : ""
              }}
              {{ formData.hour ? "at" : "" }}
              {{ formData.hour }}:{{ formData.minute }}
              {{ use12Hour ? formData.am_pm : "" }}
            </p>
          </div>
        </div>
        <div v-if="formData.is_repeated" class="is-repeated-form">
          <el-form
            :rules="isRepeatedRules"
            ref="isRepeatedForm"
            :model="formData">
            <el-form-item prop="repeated_period" label="Select repeat period">
              <el-select v-model="formData.repeated_period">
                <el-option
                  v-for="(option, index) in repeatedSchedulingOptions"
                  :key="index"
                  :label="option.label"
                  :value="option.repeated_period"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="col-md-12" v-if="errors">
          <Alert :message="errors" :type="'alert-danger'" />
        </div>
        <div
          class="action d-flex justify-content-between mx-4 contains-button-container">
          <button class="cancel" v-if="!isEdit && !fromVoice" @click="cancel">
            Cancel
          </button>
          <button class="cancel" v-if="isEdit || fromVoice" @click="back">
            Back
          </button>
          <el-button
            type="primary"
            :loading="loading"
            @click="scheduleMessage('ruleForm')"
            style="padding-top: 0.5em; padding-bottom: 0.5em"
            >Schedule Message</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import Callender from "@/components/others/calender.vue";
  import loginType from "@/helpers/loginType.js";
  import moment from "moment";
  export default {
    name: "calender",
    props: {
      payload: {
        type: Object,
      },
    },
    components: {
      Callender,
    },
    data() {
      return {
        use12Hour: false,
        repeatedSchedulingOptions: [
          {
            label: "Daily",
            repeated_period: "DAILY",
          },
          {
            label: "Once Every Week",
            repeated_period: "1_WEEK",
          },
          {
            label: "Once Every 2 Weeks",
            repeated_period: "2_WEEK",
          },
          {
            label: "Once Every 3 Weeks",
            repeated_period: "3_WEEK",
          },
          {
            label: "Once Every Month",
            repeated_period: "MONTHLY",
          },
        ],
        selectedDate: "",
        hours: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23,
        ],
        periods: ["AM", "PM"],
        error: "",
        minutes: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44,
          45,
          46,
          47,
          48,
          49,
          50,
          51,
          52,
          53,
          53,
          54,
          55,
          56,
          57,
          58,
          59,
        ],
        formData: {
          hour: "",
          minute: "",
          period: "00",
          am_pm: "AM",
          is_repeated: false,
          repeated_period: "",
        },
        rules: {
          hour: [{ required: true, message: "required", trigger: "blur" }],
          minute: [{ required: true, message: "required", trigger: "blur" }],
          period: [{ required: true, message: "required", trigger: "blur" }],
        },
        isRepeatedRules: {
          repeated_period: [
            {
              required: true,
              message: "Period is required",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        selectedKey: "",
        loading: false,
        messageContent: {},
        errors: "",
        fromVoice: false,
      };
    },
    methods: {
      sheduleVoiceMessage(date) {
        this.errors = "";
        this.loading = true;
        let formData = new FormData();
        formData.append("campaign_name", this.payload.campaign_name);
        if (this.payload.voice_template_id) {
          formData.append("voice_template_id", this.payload.voice_template_id);
        } else {
          formData.append("audio", this.payload.audio);
        }

        if (this.formData.is_repeated) {
          formData.append("is_repeated", this.formData.is_repeated);
          formData.append("repeated_period", this.formData.repeated_period);
        }

        formData.append("scheduled_time", date);
        let voiceFrom = store.getters["uistate/voiceFrom"];

        if (voiceFrom == "quick") {
          formData.append("recipients_format", this.payload.recipients_format);
          formData.append("recipients", this.payload.recipients);
          return store
            .dispatch("voice/voiceQuick", formData)
            .then((res) => {
              if (res) {
                this.loading = false;
                res.data.from = "individual";
                this.$emit("saveVoiceMessage", res.data);
                // Analytics
                this.$mixpanel.track("Send Scheduled Message Voice");
                this.$mixpanel.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanel.people.increment(
                  "User Send Scheduled Message Voice"
                );
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Send Scheduled Message Voice");
                  this.$mixpanelFranco.identify(
                    store.getters["auth/currentUser"].phone
                  );
                  this.$mixpanelFranco.people.increment(
                    "User Send Scheduled Message Voice"
                  );
                }
              }
            })
            .catch((err) => {
              this.loading = false;
              if (err?.response?.data == undefined) {
                this.errors = "Network error. try again.";
              }
              if (err.response.status == 404) {
                this.errors = err.response.data.message;
              }
            });
        }

        if (voiceFrom == "group") {
          formData.append("group_ids[]", JSON.stringify(this.payload.groupIDs));
          store
            .dispatch("voice/voiceGroup", formData)
            .then((res) => {
              if (res) {
                this.loading = false;
                res.data.from = "group";
                this.$emit("saveVoiceMessage", res.data);
                this.$mixpanel.track("Send Scheduled Message Voice");
                this.$mixpanel.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanel.people.increment(
                  "User Send Scheduled Message Voice"
                );
                // for franco
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Send Scheduled Message Voice");
                  this.$mixpanelFranco.identify(
                    store.getters["auth/currentUser"].phone
                  );
                  this.$mixpanelFranco.people.increment(
                    "User Send Scheduled Message Voice"
                  );
                }
              }
            })
            .catch((err) => {
              this.loading = false;
              if (err?.response?.data == undefined) {
                this.errors = "Network error. try again.";
              }
              if (err.response.status == 404) {
                this.errors = err.response.data.message;
              }
            });
        }
        if (voiceFrom == "excel") {
          formData.append("excel_file", this.payload.excel_contacts);
          const formDataFromStore = store.state.modal.formData;
          formData.append("phone_number_column", formDataFromStore.header);
          store
            .dispatch("voice/quickExcel", formData)
            .then((res) => {
              if (res) {
                this.loading = false;
                res.data.from = "excel";
                this.$emit("saveVoiceMessage", res.data);
                // Analytics
                this.$mixpanel.track("Send Scheduled Message Voice");
                this.$mixpanel.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanel.people.increment(
                  "User Send Scheduled Message Voice"
                );

                // for franco
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Send Scheduled Message Voice");
                  this.$mixpanelFranco.identify(
                    store.getters["auth/currentUser"].phone
                  );
                  this.$mixpanelFranco.people.increment(
                    "User Send Scheduled Message Voice"
                  );
                }
              }
            })
            .catch((err) => {
              this.loading = false;
              if (err?.response?.data == undefined) {
                this.errors = "Network error. try again.";
              }
              if (err.response.status == 404) {
                this.errors = err.response.data.message;
              }
            });
        }
      },
      back() {
        const fromSchedule = store.state.modal.fromSchedule;
        if (fromSchedule) {
          return store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
        }
        if (this.fromVoice) {
          return this.cancel();
        }
        this.$emit("backFromMessage");
      },
      cancel() {
        this.$emit("cancelCalender");
      },
      rescheduleMessage(date) {
        this.loading = true;
        let payload = {
          sender_id: this.messageContent.sender_id,
          message: this.messageContent.body,
          campaign_id: this.messageContent.id,
          campaign_name: this.messageContent.title,
          date_time: date,
          is_repeated: this.formData.is_repeated,
          repeated_period:
            this.formData.is_repeated == false
              ? null
              : this.formData.repeated_period,
        };
        store.dispatch("modal/updateSingleField", {
          key: "date_time",
          value: date,
        });
        if (loginType() == "sms") {
          //when we are on sms use editScheduledMessages
          store
            .dispatch("campaigns/editScheduledMessages", payload)
            .then((response) => {
              this.loading = false;
              let payload = {
                data: response.data.data,
                key: this.selectedKey,
                message: response.data.message,
              };
              this.$emit("editTemplate", payload);
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.data == undefined) {
                this.errors = "An error occured try again.";
              }
              if (err.response && err.response.status === 404) {
                this.errors = err.response.data.message;
              } else {
                this.$notify({
                  title: "Error",
                  message: err.response.data.message,
                  type: "error",
                });
              }
            });
        } else {
          //Otherwise use editScheduledVoiceMessages from voice
          store
            .dispatch("voice/editScheduledVoiceMessages", payload)
            .then((response) => {
              this.loading = false;
              let payload = {
                data: response.data.data,
                key: this.selectedKey,
                message: response.data.message,
              };
              this.$emit("editTemplate", payload);
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.data == undefined) {
                this.errors = "An error occured try again.";
              }
              if (err.response && err.response.status === 404) {
                this.errors = err.response.data.message;
              }
            });
        }
      },
      scheduleMessage(formName) {
        if (!this.selectedDate) {
          this.error = "Please Select date";
        }
        if (
          this.formData.is_repeated == true &&
          this.formData.repeated_period == ""
        ) {
          this.$refs["isRepeatedForm"].validate((valid) => {
            return valid;
          });
          return;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.selectedDate) {
              // convert date and time to date time
              let time = `${this.formData.hour}:${this.formData.minute}:${
                this.formData.period
              } ${this.use12Hour == true ? this.formData.am_pm : ""}`;
              let newDateTime = `${this.selectedDate} ${time}`;
              newDateTime = moment(newDateTime, "YYYY-MM-DD h:mm:ss a").format(
                "YYYY-MM-DD HH:mm:ss"
              );
              if (this.isEdit) {
                return this.rescheduleMessage(newDateTime);
              }
              if (this.fromVoice) {
                return this.sheduleVoiceMessage(newDateTime);
              }
              store.dispatch("uistate/setIsShowScheduleButtonConfirm", false);

              this.$emit("selectedDate", {
                date_time: newDateTime,
                is_repeated: this.formData.is_repeated,
                repeated_period: this.formData.repeated_period,
              });
              store.dispatch("modal/updateSingleField", {
                key: "date_time",
                value: newDateTime,
              });
            }
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      handleDate(data) {
        this.selectedDate = data[0].formatted;
      },
    },
    mounted() {
      let editedData = store.getters["uistate/contactForm"];
      let isFromvoice = store.getters["uistate/fromVoice"];
      let quickVoice = store.getters["uistate/quickVoice"];
      const { date_time } = store.state.modal.formData;
      if (date_time) {
        let date = date_time.split(" ")[0];
        let time = date_time.split(" ")[1];
        this.selectedDate = date;
        this.formData.hour = time.split(":")[0];
        this.formData.minute = time.split(":")[1];
        this.formData.period = time.split(":")[2];
      }
      if (isFromvoice || quickVoice) {
        this.fromVoice = true;
      }
      if (editedData.editType && editedData.editType == "editMessage") {
        this.messageContent = editedData.editData.selectedMessage;
        this.selectedKey = editedData.editData.selectedKey;
        let gotEditDate = editedData.editData.selectedMessage.date_time;
        let date = gotEditDate.split(" ")[0];
        let time = gotEditDate.split(" ")[1];
        this.selectedDate = date;
        this.formData.hour = time.split(":")[0];
        this.formData.minute = time.split(":")[1];
        this.formData.period = time.split(":")[2];
        this.formData.is_repeated =
          editedData.editData.selectedMessage.is_repeated == 1 ? true : false;
        this.formData.repeated_period =
          editedData.editData.selectedMessage.repeated_period;
        this.isEdit = true;
      }
    },
    computed: {
      computedHours() {
        if (this.use12Hour == true) {
          // convert this.formdata.hour input to 12 hour format
          if (this.formData.hour > 12) {
            this.formData.am_pm = "PM";
            this.formData.hour = this.formData.hour - 12;
          }
          // handle case where hour is 0
          if (this.formData.hour == 0) {
            this.formData.hour = 12;
          }
          return this.hours.slice(1, 13);
        }

        if (this.use12Hour == false && this.formData.hour != 0) {
          // convert this.formdata.hour input to 24 hour format
          if (this.formData.am_pm == "PM") {
            this.formData.hour = parseInt(this.formData.hour) + 12;
          }
          if (this.formData.am_pm == "AM" && this.formData.hour == 12) {
            this.formData.hour = 0;
          }
        }
        return this.hours;
      },
    },
  };
</script>

<style lang="scss">
  .__hour .hour,
  .__minute .__select,
  .__timeframe .__select {
    width: 70px;
    height: 48px !important;
    margin: 1em;
  }

  el-option {
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 36px;
    color: #575761;
  }

  input {
    background-color: #f3f3f4 !important;
    border-radius: 5px;
    height: 48px !important;
  }

  .__separator {
    margin-top: 1em;
    font-size: 22px;
    font-weight: 600;
    line-height: 24.31px;
  }

  .__time__inputs {
    display: flex;
    justify-content: center;
  }

  ._seletedDate_text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #f7921c;
    margin-bottom: 0px;
  }

  .selected_date {
    height: 53.03px;
    background: rgba(80, 31, 166, 0.1);
    border-radius: 5px;
    margin-bottom: 3em;
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vc-container {
    width: 330px;
    border-color: transparent;
    background-color: transparent;
  }

  .__top__text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
    text-align: center;
    margin-top: 3em;
    margin-bottom: 1em;
  }

  .__nav_btns {
    width: 40.3px;
    height: 40.3px;
    background-color: #fff;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1.34346px 1.34346px rgba(0, 14, 51, 0.05);
    border-radius: 20.15px;
  }

  .__nav_btns:active,
  .__nav_btns:hover {
    background-color: #fff !important;
  }

  .__calender {
    .__title p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #484a4f;
    }

    ._display_message {
      background-color: rgba(109, 34, 242, 0.1) !important;
      border-radius: 5px;
      height: 43px;

      .__selected_text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #f7921c;
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .calender {
    max-height: 100vmin;
    padding-bottom: 0 !important;
  }

  .calendar-object * {
    max-height: 38vh !important;
    margin: 0 auto !important;
    padding: 0 !important;
  }

  .separator-container {
    padding: 0;
    display: flex;
    align-items: flex-start;
    margin: 0;
    justify-content: center;
  }

  .__separator {
    height: 100%;
    position: relative;
    top: 25px;
  }

  .separator-container * {
    height: 100%;
    margin: 0;
  }

  .contains-date * {
    margin-block: 1rem;
  }

  .contains-button-container {
    margin-block: 0.8rem !important;
    padding: 0;
    position: relative;
    // padding-top: rem;
  }

  .is-repeated-form {
    width: 100%;
    margin: 1rem auto;
  }

  .is-repeated-form form > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 500px) {
    .calender {
      max-height: 100vh;
      padding-bottom: 0 !important;
    }

    .is-repeated-form form > div {
      flex-direction: column;
    }
  }

  .__main__calender {
    height: 33vh;
    overflow-y: scroll;
  }

  .main-modal-form-calendar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
  }

  .contains-checkbox {
    height: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 2rem;
  }

  .__minute div > div:nth-child(2),
  .__hour div:nth-child(2) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
</style>
