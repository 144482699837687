import "core-js/modules/es.array.push.js";
import $ from "jquery";
function check_message_encoding(user_input) {
  var gsm_7_bit_chars = "@Â£$Â¥Ã¨Ã©Ã¹Ã¬Ã²Ã‡\\nÃ˜Ã¸\\rÃ…Ã¥Î”_Î¦Î“Î›Î©Î Î¨Î£Î˜ÎžÃ†Ã¦ÃŸÃ‰ !\\\"#Â¤%&'()*+,-./0123456789:;<=>?Â¡ABCDEFGHIJKLMNOPQRSTUVWXYZÃ„Ã–Ã‘ÃœÂ§Â¿abcdefghijklmnopqrstuvwxyzÃ¤Ã¶Ã±Ã¼Ã ";
  var gsm_7_bit_ex_chars = "\\^{}\\\\\\[~\\]|â‚¬";
  var gsm_7_bit_reg_exp = RegExp("^[" + gsm_7_bit_chars + "]*$");
  var gsm_7_bit_reg_ex_exp = RegExp("^[" + gsm_7_bit_chars + gsm_7_bit_ex_chars + "]*$");
  var gsm_7_bit_ex_only_reg_exp = RegExp("^[\\" + gsm_7_bit_ex_chars + "]*$");
  var diff = [...user_input].filter(v => [...gsm_7_bit_chars].indexOf(v) == -1);
  let utf_data;
  switch (false) {
    case user_input.match(gsm_7_bit_reg_exp) === null:
      // return 'GSM_7BIT';
      utf_data = {
        encoding: "GSM_7BIT",
        unicode_chars: diff
      };
      return utf_data;
    case user_input.match(gsm_7_bit_reg_ex_exp) === null:
      // return 'GSM_7BIT_EX';
      utf_data = {
        encoding: "GSM_7BIT_EX",
        unicode_chars: diff
      };
      return utf_data;
    default:
      utf_data = {
        encoding: "UTF16",
        unicode_chars: diff
      };
      // return 'UTF16';
      return utf_data;
  }
}
function countGsm7BitEx(user_input) {
  var gsm_7_bit_ex_chars = "\\^{}\\\\\\[~\\]|â‚¬";
  var gsm_7_bit_ex_only_reg_exp = RegExp("^[\\" + gsm_7_bit_ex_chars + "]*$");
  var char2, chars;
  chars = function () {
    var _i, _len, _results;
    _results = [];
    for (_i = 0, _len = user_input.length; _i < _len; _i++) {
      char2 = user_input[_i];
      if (char2.match(gsm_7_bit_ex_only_reg_exp) != null) {
        _results.push(char2);
      }
    }
    return _results;
  }.call(this);
  return chars.length;
}
function get_length_and_segment(a) {
  var count, encoding, length, messages, per_message, remaining;
  var messageLength = {
    GSM_7BIT: 160,
    GSM_7BIT_EX: 160,
    UTF16: 70
  };
  var user_input = a;
  var encoding_type = check_message_encoding(user_input);
  // return;
  var length = user_input.length;
  if (encoding_type.encoding === "GSM_7BIT_EX") {
    length += countGsm7BitEx(user_input);
  }
  per_message = messageLength[encoding_type.encoding];
  messages = Math.ceil(length / per_message);
  if (length <= per_message && encoding_type.encoding != "UTF16") {
    messages = 1;
    remaining = per_message - length;
  } else {
    messages = Math.ceil(length / 153);
    remaining = per_message * messages - length;
  }
  // check for utf16
  if (encoding_type.encoding == "UTF16") {
    messages = Math.ceil(length / 70);
    remaining = per_message * messages - length;
  }
  remaining = per_message * messages - length;
  if (remaining == 0 && messages == 0) {
    remaining = per_message;
  }
  return {
    encoding: encoding_type.encoding,
    length: length,
    per_message: per_message,
    remaining: remaining,
    messages: messages,
    utf_chars: encoding_type.unicode_chars
  };
}

// $(".sadat").keyup(function () {
//     var a = $('#body_mes').val();
//     var oracle = get_length_and_segment(a);
//
//     if(oracle.encoding == "UTF16"){
//         $("#utf-alert").show();
//         $("#encoding_type").val('Encoding: ' + oracle.encoding);
//         $("#encoding_type").css("background-color", "orange");
//         var unicode_alert = "Charge is " + (parseInt(oracle.messages)) + " SMS per recipient due to these characters: " + oracle.utf_chars + " click here";
//         $("#utf-alert").html(unicode_alert);
//         $("#length").val('Length: ' + oracle.length);
//         $("#per_message").val('Per Message: ' + oracle.per_message);
//         $("#remaining").val('Remaining: ' + oracle.remaining);
//         $("#messages").val('Messages: ' + oracle.messages);
//     }else if(oracle.encoding == "GSM_7BIT" || oracle.encoding == "GSM_7BIT_EX"){
//         $("#encoding_type").val('Encoding: ' + oracle.encoding);
//         $("#length").val('Length: ' + oracle.length);
//         $("#per_message").val('Per Message: ' + oracle.per_message);
//         $("#remaining").val('Remaining: ' + oracle.remaining);
//         $("#messages").val('Messages: ' + oracle.messages);
//         $("#utf-alert").hide();
//     }
// });

export default get_length_and_segment;