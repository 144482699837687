import { RenderlessCalendar } from "vue-renderless-calendar/lib/index";
import CalendarCell from "./CalenderCell.vue";
export default {
  name: "Calendar",
  components: {
    CalendarCell,
    RenderlessCalendar
  },
  props: {
    defaultDateFromApi: {
      required: false
    }
  },
  methods: {
    handleDateChange(payload) {
      this.$emit("handleDate", payload);
    },
    log(something) {}
  },
  computed: {
    currentLocale() {
      return this.$store.getters["language/currentLanguage"];
    }
  }
};