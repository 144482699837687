var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em"
    }
  }, [_c('div', {
    staticClass: "col-md-12 d-flex _ justify-content-center flex-column align-items-center",
    staticStyle: {
      "padding-right": "3em",
      "padding-left": "3em"
    },
    attrs: {
      "lock-scroll": false
    }
  }, [_vm.isSpecialRoute ? _c('div', {
    staticClass: "request_did"
  }, [_c('h6', [_vm._v(" TrueCaller may tag default voice SMS IDs with suggested names from call recipients, and we have no control over this; ")]), _c('h6', [_vm._v(" for a premium voice caller ID number (at GHS 100) with potential avoidance,"), _c('a', {
    staticClass: "flink",
    attrs: {
      "href": "#",
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.ConfirmationDialogVisible.apply(null, arguments);
      }
    }
  }, [_vm._v("click here")])]), _c('div', {
    staticClass: "confirmation-modal"
  }, [_c('el-dialog', {
    attrs: {
      "visible": _vm.isConfirmationDialogVisible,
      "title": 'Confirmation',
      "width": "40%",
      "modal-append-to-body": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.isConfirmationDialogVisible = $event;
      }
    }
  }, [_vm.isConfirmationDialogVisible ? _c('div', {
    staticClass: "heading"
  }, [_c('p', [_vm._v(" Are you sure you want to request for a Dedicated Voice Caller ID? ")])]) : _vm._e(), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.isConfirmationDialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "danger",
      "loading": _vm.buttonLoading
    },
    on: {
      "click": _vm.submitChange
    }
  }, [_vm._v("Confirm")])], 1)])], 1)]) : _vm._e(), _c('h3', {
    staticClass: "__header"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.showQuickSMS ? _c('button', {
    staticClass: "_btn",
    class: [_vm.messageAction == 'individual' ? 'activeBtn' : ''],
    on: {
      "click": _vm.sendToAnIndividual
    }
  }, [_vm._v(" " + _vm._s(_vm.individual) + " ")]) : _vm._e(), _c('button', {
    staticClass: "_btn",
    class: [_vm.messageAction == 'group' ? 'activeBtn' : ''],
    on: {
      "click": _vm.sendToGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.groupTitle) + " ")]), _vm.showQuickSMS ? _c('button', {
    staticClass: "_btn",
    class: [_vm.messageAction == 'excel' ? 'activeBtn' : ''],
    on: {
      "click": _vm.importContacts
    }
  }, [_vm._v(" " + _vm._s(_vm.contacts) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-12 mt-5 mb-5"
  }, [_c('button', {
    staticClass: "cancel notranslate",
    on: {
      "click": _vm.cancelModal
    }
  }, [_vm._v(" " + _vm._s(_vm.fromView ? _vm.$t("misc.button.back") : _vm.$t("misc.button.cancel")) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };