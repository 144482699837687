import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import $ from "jquery";
import store from "@/state/store.js";
import createMessageTemplate from "./createMessageTemplate.vue";
import SendMessage from "./sendSimpleMessage.vue";
import SelectSenderId from "./selectSenderID.vue";
import ToIndividual from "./toIndividual";
import SendToGroup from "./sendToGroup.vue";
import ConfirmMessage from "./messageConfirmation.vue";
import ImportContactFromExcel from "./sendToExcelSheet.vue";
import SMSDetails from "./smsDetails.vue";
import DeleteMessage from "./messageDeleteEditModal.vue";
import ViewMessage from "./view.vue";
import Calender from "./calender.vue";
import VoiceTemplate from "../voice/voiceTemplate.vue";
import VoicePlayer from "../voice/voicePlayer.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    payload: {
      required: true,
      type: Object
    },
    voiceData: {
      type: Object
    },
    // variable to check whether the user want
    // simple message or
    // tamplate message or
    // scheduled message
    from: {
      required: true,
      type: String
    }
  },
  components: {
    createMessageTemplate,
    SendMessage,
    SelectSenderId,
    ToIndividual,
    SendToGroup,
    ConfirmMessage,
    ImportContactFromExcel,
    SMSDetails,
    Calender,
    DeleteMessage,
    ViewMessage,
    VoiceTemplate,
    VoicePlayer
  },
  name: "new-messages",
  data() {
    return {
      needsRefresh: 0,
      messages: "",
      success: "",
      errors: [],
      modalType: "",
      rules: {},
      payloadData: {},
      formData: {
        campaign_name: "",
        excel_contacts: "",
        title: "",
        message: "",
        sender_id: "",
        recipients: "",
        recipients_format: "",
        from: "",
        messageType: "",
        groupIDs: [],
        body: "",
        type: "",
        date_time: "",
        audio: [],
        voice_template_id: "",
        headers: [],
        header: "",
        hideHeaders: false
      },
      sendMessage: {},
      sendMessageRules: {},
      userSelectionType: "",
      successFrom: ""
    };
  },
  mounted() {
    if (this.$route.path.includes("/admin/users")) {
      return;
    } else {
      store.dispatch("senderid/getSenderIDs");
    }
  },
  computed: {
    ...mapGetters(["modal/getFormData", "modal/nexPage", "modal/prevPage"]),
    validateHeader() {
      if (this.messageForm.type !== "selectGroup") {
        return true;
      } else if (this.messageForm.type !== "view") {
        return true;
      }
      return false;
    },
    messageForm() {
      return store.getters["uistate/contactForm"];
    },
    groups() {
      return store.getters["groups/all_groups"];
    },
    chartOptions: function () {
      return {
        colors: ["#36D962", "#30A1F0", "#F0E930", "#F7921C"],
        legend: {
          fontSize: "14px",
          fontWeight: 400,
          floating: false,
          itemMargin: {},
          formatter: function (seriesName, opts) {
            let total = 0;
            let series = opts.w.globals.series;
            let singleSeries = opts.w.globals.series[opts.seriesIndex];
            for (let i = 0; i < series.length; i++) {
              total += series[i];
            }
            let perCentage = parseInt(singleSeries / total * 100);
            return '<div class="legend-info" style="display:inline-block; margin-bottom:1.5em!important;">' + "<strong>" + singleSeries + "</strong>" + "<span>" + seriesName + "</span>" + "<span>" + perCentage + "%" + "</span>" + "</div>";
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: ["Delivered", "Submitted", "Expired", "Undelivered"],
        plotOptions: {
          pie: {
            donut: {
              size: "90%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "29px",
                  lineHeight: "32px",
                  formatter: function (val) {
                    return val;
                  }
                },
                fromVoice: true,
                total: {
                  show: true,
                  label: "Total Messages",
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "13px",
                  textTransform: "capitalize",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        series: [400, 100, 300, 500]
      };
    }
  },
  methods: {
    scheduleVoiceMessage(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
    },
    FromVoiceToSelectUser(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
      store.dispatch("uistate/addContactFormData", {
        title: "Send Message",
        type: "selectSenderId",
        fromVoice: true
      });
    },
    saveVoiceTemplate(payload) {
      this.success = payload.message;
      this.$emit("saveVoiceTemplate", payload.data);
    },
    saveVoiceMessage(payload) {
      this.success = payload.message;
      if (payload.voiceSent) {
        return;
      }
      if (payload.from && (payload.from == "individual" || payload.from == "group" || payload.from == "excel")) {
        this.success = payload.message;
        if (payload.from == "excel") {
          this.successFrom = "excel";
        }
        if (payload.from == "individual") {
          this.successFrom = "individual";
        }
        if (payload.from == "group") {
          this.successFrom = "group";
        }
        return this.$emit("saveTemplate", payload.data);
      }
      // this.$emit('saveVoiceMessage', payload.data)
    },
    sendTemplateMessage(payload) {
      this.formData = {
        ...this.formData,
        ...payload
      };
      return store.dispatch("uistate/addContactFormData", {
        title: "Select sender ID",
        type: "selectSenderId",
        isFromTemplate: true
      });
    },
    backFromMessage() {
      return store.dispatch("uistate/addContactFormData", {
        title: "",
        type: "view"
      });
    },
    successMessage(data) {
      let messageData = data.data;
      localStorage.setItem("askedForReview", data.askForFeedback, data.askForCategory);
      if (messageData.date_time) {
        this.$emit("needsRefreshOnScheduled");
        this.success = "Campaign successfully scheduled";
        return;
      }
      this.hideModal();
      // Controls redirection after sending sms message
      this.$router.push(`/sms/campaign/${messageData.campaign_id}/details`).then(() => {
        this.$router.go();
      });
    },
    selectedDate(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
      store.dispatch("uistate/addContactFormData", {
        title: "Confirmation Message",
        type: "confirmMessage"
      });
    },
    cancelCalender() {
      let voiceFrom = store.getters["uistate/voiceFrom"];
      if (voiceFrom == "quick") {
        return store.dispatch("uistate/addContactFormData", {
          title: "Select Individuals",
          type: "selectIndividual"
        });
      }
      if (voiceFrom == "group") {
        return store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "selectGroup"
        });
      }
      if (voiceFrom == "excel") {
        return store.dispatch("uistate/addContactFormData", {
          title: "Excel messaging",
          type: "importContacts"
        });
      }
      if (store.getters["uistate/fromMessage"]) {
        return store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "sendMessage"
        });
      }
      if (store.getters["uistate/fromTemplate"]) {
        this.checkSelectionType();
      }
    },
    backFromComfirmation() {
      if (store.getters["uistate/fromMessage"]) {
        return store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "sendMessage"
        });
      }
      if (store.getters["uistate/fromTemplate"]) {
        this.checkSelectionType();
      }
    },
    checkSelectionType() {
      if (this.formData.from == "individual") {
        return store.dispatch("uistate/addContactFormData", {
          title: "Select Individuals",
          type: "selectIndividual"
        });
      }
      if (this.formData.from == "group") {
        return store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "selectGroup"
        });
      }
      if (store.getters["uistate/quickVoice"]) {
        store.dispatch("uistate/setFromVoice", true);
        return store.dispatch("uistate/addContactFormData", {
          title: "Create a voice message",
          type: "voiceTemplate",
          quickVoice: true
        });
      }
      //'excel'
      if (this.formData.from == "excel") {
        return store.dispatch("uistate/addContactFormData", {
          title: "Excel messaging",
          type: "importContacts",
          fromVoice: false
        });
      }
    },
    cancelMessageModal(type) {
      if (type) {
        return store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "view"
        });
      }
      this.checkSelectionType();
    },
    deleteFromView(data) {
      this.payloadData = data;
    },
    deleteSuccess({
      message,
      key
    }) {
      this.success = message;
      this.$emit("deleteSuccess", key);
    },
    saveTemplate(payload) {
      this.success = "Template created successfully";
      this.$emit("saveTemplate", payload);
    },
    editTemplate(payload) {
      this.success = payload.message;
      this.$emit("editTemplate", payload);
      store.dispatch("campaigns/fetchTemplate");
    },
    closeModal() {
      this.hideModal();
      this.calledRefresh();
    },
    calledRefresh() {
      this.needsRefresh = this.needsRefresh + 1;
      store.dispatch("uistate/setIsEditToFalse", false);
    },
    closeMainModal() {
      this.hideModal();
    },
    closeCreateTemplateModal(type) {
      if (type == "cancel") {
        return this.hideModal();
      }

      //    TODO: check if this is needed and why it fixes every issue
      return store.dispatch("uistate/addContactFormData", {
        title: "",
        type: "view"
      });
    },
    hideModal() {
      this.success = "";
      store.dispatch("modal/clearFormData");
      $("#messages").modal("hide");
      this.calledRefresh();
      this.$emit("closeBirthday");
      if (this.successFrom == "excel" || this.successFrom == "group" || this.successFrom == "individual") {
        // store.dispatch('modal/showFeedbackModal', true)
      }
      this.formData = {};
      this.payloadData = {};
    },
    templateSuccess(payload) {
      this.success = payload;
    },
    scheduleMessage(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
      store.dispatch("uistate/addContactFormData", {
        title: "Schedule message",
        type: "calender"
      });
    },
    checkMessageType() {
      if (store.getters["uistate/fromMessage"]) {
        store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "sendMessage"
        });
      }
      if (store.getters["uistate/fromTemplate"]) {
        store.dispatch("uistate/addContactFormData", {
          title: "Confirmation Message",
          type: "confirmMessage"
        });
      }
      if (store.getters["uistate/quickVoice"]) {
        store.dispatch("uistate/addContactFormData", {
          title: "Create a voice message",
          type: "voiceTemplate",
          quickVoice: true
        });
      }
    },
    goToSelectUsers(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
      if (store.getters["uistate/fromTemplate"]) {
        store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "selectSenderId"
        });
      } else {
        store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "selectSenderId",
          fromTemplate: true
        });
      }
    },
    goToTypeMessage(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
      this.checkMessageType();
    },
    backToTypeMessage(data) {
      // get recipient length
      if (data.recipients_format == "comma") {
        this.formData.totalNumberOfRecipient = data.recipients.split(",").length;
      }
      if (data.recipients_format == "new_line") {
        this.formData.totalNumberOfRecipient = data.recipients.split("\n").length;
      }
      this.formData.from = "individual";
      this.formData = {
        ...this.formData,
        ...data
      };
      return this.checkMessageType();
    },
    backToIndividual() {
      store.dispatch("uistate/addContactFormData", {
        title: "Select Individuals",
        type: "selectIndividual"
      });
    },
    backSimpleMessage() {
      store.dispatch("uistate/addContactFormData", {
        title: "Send Message",
        type: "sendMessage"
      });
    },
    sendSimpleData(data) {
      this.formData = {
        ...this.formData,
        ...data
      };
      if (this.formData.from == "group" || this.formData.from == "excel" || this.formData.from === "individual") {
        store.dispatch("uistate/addContactFormData", {
          title: "Confirmation Message",
          type: "confirmMessage"
        });
      }
    },
    backToSelect() {
      store.dispatch("uistate/addContactFormData", {
        title: this.fromVoice ? "Send Message" : "Select sender ID",
        type: "selectSenderId",
        fromVoice: this.fromVoice ? true : false
      });
    },
    handleCancelModal(data) {
      this.formData = {
        campaign_name: "",
        excel_contacts: "",
        title: "",
        message: "",
        sender_id: "",
        recipients: "",
        recipients_format: "",
        from: "",
        messageType: "",
        groupIDs: [],
        body: "",
        type: "",
        date_time: "",
        audio: [],
        voice_template_id: "",
        headers: [],
        header: "",
        hideHeaders: false
      };
      let fromSelectSenderIDForVoice = store.getters["uistate/fromVoice"];
      if (fromSelectSenderIDForVoice) {
        store.dispatch("uistate/clerafromSelectSenderIDForVoice");
        $("#messages").modal("hide");
        return store.dispatch("uistate/removeContactFormData");
      }
      if (data.type && data.type == "fromVoice") {
        this.fromVoice = true;
        return store.dispatch("uistate/addContactFormData", {
          title: "Create a voice message",
          type: "voiceTemplate"
        });
      }
      if (store.getters["uistate/fromTemplate"]) {
        return store.dispatch("uistate/addContactFormData", {
          title: "Create a template",
          type: "template",
          fromTemplate: true
        });
      }
      $("#messages").modal("hide");
      store.dispatch("uistate/removeContactFormData");
    },
    handleBack() {
      store.dispatch("uistate/addContactFormData", {
        title: "Create a template",
        type: "template"
      });
    },
    backtoSelectSpecial() {
      const pattern = /\[(fname|lname|fullname)\]/;
      let doesItIncludeSpecial = pattern.test(this.formData.message);
      if (this.formData.message == "" || doesItIncludeSpecial == false) {
        store.dispatch("uistate/addContactFormData", {
          title: this.fromVoice ? "Send Message" : "Select sender ID",
          type: "selectSenderId",
          fromVoice: this.fromVoice ? true : false
        });
      } else {
        store.dispatch("uistate/addContactFormData", {
          title: "Review Your Content",
          type: "view",
          fromVoice: this.fromVoice ? true : false
        });
      }
    },
    setToCombacktoModal() {
      return true;
    }
  }
};