import "core-js/modules/es.array.reduce.js";
import VueApexCharts from 'vue-apexcharts';
export default {
  props: {
    chartDataOptions: {
      required: true
    }
  },
  components: {
    apexcharts: VueApexCharts
  },
  data: function () {
    return {
      // series1: [2, 4, 5, 6],
      // labels1: ['a', 'b', 'c', 'd'],
    };
  },
  methods: {},
  computed: {
    chartOptions: function () {
      return {
        colors: ['#36D962', '#30A1F0', '#F0E930', '#F7921C'],
        legend: {
          fontSize: '14px',
          fontWeight: 400,
          floating: false,
          offsetX: 0,
          offsetY: 0,
          height: 300,
          // position: 'right',
          itemMargin: {
            horizontal: 5,
            vertical: 20
          },
          formatter: function (seriesName, opts) {
            let total = 0;
            let series = opts.w.globals.series;
            let singleSeries = opts.w.globals.series[opts.seriesIndex];
            for (let i = 0; i < series.length; i++) {
              total += series[i];
            }
            let perCentage = parseInt(singleSeries / total * 100);
            return '<div style="display:inline-block;"">' + '<div style="width:350px;padding-left:1rem;">' + '<strong style="padding-right:3rem;">' + singleSeries + '</strong>' + '<span styple="">' + seriesName + '</span>' + '<span style="float:right!important">' + perCentage + '%' + '</span>' + '</div>' + '</div>';
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: ['Delivered', 'Submitted', 'Expired', 'Undelivered'],
        plotOptions: {
          pie: {
            size: 200,
            expandOnClick: false,
            donut: {
              size: "88%",
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "29px",
                  lineHeight: "32px",
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  label: 'Total Messages',
                  color: '#575761',
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "13px",
                  textTransform: 'capitalize',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        series: [400, 100, 300, 500],
        responsive: [{
          breakpoint: 1000,
          options: {
            legend: {
              position: "bottom",
              height: 220,
              itemMargin: {
                horizontal: 2,
                vertical: 0
              }
            }
          }
        }]
      };
    }
  }
};